import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Ellipsis } from "lucide-react";
import ModalInfo from "./ModalInfo";

interface DropdownProps {
  items: { label: string; value: string; available: boolean }[];
  dropdownIsOpen: boolean;
  setDropdownIsOpen: (value: boolean) => void;
  src?: string;
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  position: absolute;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  height: auto;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  right: 0;
  z-index: 100;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 40px 16px rgba(0, 0, 0, 0.02);
  min-width: 180px;
  max-width: 380px;
  box-sizing: border-box; /* Ensure padding and borders are included in width */
  border: 1px solid #f3f3f3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DropdownItem = styled(Link)`
  display: flex;
  padding: 7px 10px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 8px;
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  width: 100%;
  box-sizing: border-box; /* Include padding and borders in the element's width */
  &:hover {
    background-color: #fff7f2;
  }
  text-decoration: none;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
`;

const DropDown: React.FC<DropdownProps> = ({
  src,
  items,
  dropdownIsOpen,
  setDropdownIsOpen,
}) => {
  //const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);

  const handleCampaignClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleSelect = (
    e: React.MouseEvent,
    value: string,
    available: boolean
  ) => {
    e.preventDefault();
    if (!available) {
      setShowModal(true);
      return;
    }
    //onSelect(value);
    setDropdownIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownIsOpen(false);
      }
    };

    if (dropdownIsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownIsOpen, setDropdownIsOpen]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <Ellipsis
        size={22}
        color="#6E6E6E"
        onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
      />
      <DropdownContent isOpen={dropdownIsOpen}>
        {items.map((item) => (
          <DropdownItem
            key={item.value}
            to={item.value}
            onClick={(e) => handleSelect(e, item.value, item.available)}
          >
            {item.label}
          </DropdownItem>
        ))}
      </DropdownContent>
      {showModal && (
        <ModalOverlay>
          <ModalInfo
            title="Fonctionnalité verrouillée"
            description="Ce contenu est bloqué dans la version de Parabole que vous utilisez. Contactez-nous pour plus d'informations."
            onClose={() => setShowModal(false)}
          />
        </ModalOverlay>
      )}
    </DropdownContainer>
  );
};

export default DropDown;
