import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Title from "../components/text/Title";
import logo from "../assets/img/logos/logoXL.png";
import Button from "../components/cta/Button";
import LinkText from "../components/cta/LinkText";
import ErrorMessage from "../components/errors/ErrorMessage";
// Define an interface for the props
const Container = styled.div`
  display: inline-flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #fcfcfc;
`;

const LoginHero = styled.div`
  display: flex;
  width: 494px;
  padding: 42px 0px;
  flex-direction: column;
  align-items: center;
  gap: 71px;
  flex-shrink: 0;
  border-radius: 18px;
  background: #fff;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  align-self: stretch;
`;

const Logo = styled.img`
  width: 68.595px;
  height: 69.452px;
`;

const Form = styled.form`
  display: flex;
  width: 353px;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  align-items: center;
  justify-content: center;
`;

const Inputs = styled.div`
  display: flex;
  width: 353px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const InputTitle = styled.span`
  color: #191919;
  align-self: stretch;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.14px;
  text-align: left;
`;

const InputText = styled.input`
  border: none;
  display: flex;
  height: 20px;
  padding: 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--Second-color, #fff7f2);
  &:focus {
    outline: none !important;
    border: none;
  }
`;

const About = styled.div`
  display: flex;
  width: 353px;
  height: 48px;
  justify-content: center;
  align-items: center;
`;

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const { signin, isLoading } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoginError(false);
    try {
      const success = await signin(email, password);
      if (success !== undefined && success) {
        navigate("/", { replace: true });
      } else {
        setLoginError(true);
      }
    } catch (error) {
      setLoginError(true);
    }
  };

  return (
    <Container>
      <LoginHero>
        <Top>
          <Logo src={logo} alt="logo" />
          <Title type="h1">Connexion</Title>
        </Top>
        <Form onSubmit={handleSubmit}>
          <Inputs>
            <Input>
              <InputTitle>Adresse email</InputTitle>
              <InputText
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Input>
            <Input>
              <InputTitle>Mot de passe</InputTitle>
              <InputText
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Input>
            {loginError && (
              <ErrorMessage>
                L'adresse e-mail ou le mot de passe est incorrect
              </ErrorMessage>
            )}
          </Inputs>

          <Button
            typeButton="primary"
            type="submit"
            //disabled={isLoading}
            stretch={true}
          >
            {isLoading ? "..." : "Se connecter"}
          </Button>
          <LinkText to="/signup" type={"primary"}>
            S'inscrire
          </LinkText>
        </Form>
        <About>
          <LinkText to="/forgot-password">Mot de passe oublié</LinkText>
        </About>
      </LoginHero>
    </Container>
  );
};

export default LoginPage;
