import React from "react";
import styled from "styled-components";

interface StepButtonProps {
  isActive: boolean;
  currentStep: boolean;
  onMouseEnter: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onMouseLeave: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

interface ProgressBarProps {
  completion: number;
  locked?: boolean;
}

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  width: 100%;
  z-index: 50;
`;

const StepsContainer = styled.div`
  display: flex;
  gap: 50px;
  border-radius: 20px;
  align-items: center;
  width: 100%;
  height: 6px;
  padding: 0 100px;
`;

const ProgressLine = styled.div<{ locked?: boolean }>`
  height: 100%;
  width: 100%;
  background: ${({ locked }) =>
    locked ? "#F8F8F8" : "var(--Second-color, #fff7f2)"};

  border-radius: 20px;
  z-index: -1;
  //transform: translateY(-50%);
`;

const ProgressLineFilled = styled.div<{ completion: number; locked?: boolean }>`
  position: absolute;
  height: 6px;
  background-color: ${({ locked }) => (locked ? "#CACACA" : "#ff734f")};
  width: ${({ completion }) => completion}px;
  z-index: -1;
  border-radius: 20px;
`;

const StepButton = styled.button<{
  isActive: boolean;
  currentStep: boolean;
  afterCurrentStep: boolean;
}>`
  background-color: ${({ afterCurrentStep }) =>
    afterCurrentStep ? "#393939" : "#FF734F"};
  box-sizing: border-box;
  border: none;
  box-shadow: ${({ isActive }) =>
    isActive ? "inset 0 0 0 2px #ffffff" : "none"};
  border-radius: 20px;
  stroke-width: 2px;
  stroke: #fff;
  width: 15px;
  height: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const StepMeta = styled.div`
  text-align: center;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 16px;
  font-family: "Sharp Sans";
`;

const Title = styled.span`
  text-align: center;
  font-weight: 700;
  color: #ffffff;
`;

const Description = styled.span`
  text-align: center;

  color: #ffffff;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({ completion, locked }) => {
  return (
    <ProgressBarContainer>
      <StepsContainer>
        <ProgressLine locked={locked} />
        <ProgressLineFilled completion={completion} locked={locked} />
      </StepsContainer>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
