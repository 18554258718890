import styled from "styled-components";
import { MutatingDots } from "react-loader-spinner";

interface LoaderProps {
  page?: boolean;
}

const LoadingContainer = styled.div<LoaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.page ? "100vh" : "auto")};
`;

const Loader: React.FC<LoaderProps> = ({ page }) => {
  return (
    <LoadingContainer page={page}>
      <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#FF734F"
        secondaryColor="#FF734F"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
      />
    </LoadingContainer>
  );
};

export default Loader;
