import { useState, useEffect, useMemo } from "react";
import axios from "axios";

const useAmendments = (
  fileId?: string,
  textId?: string,
  page?: number,
  filters?: {
    id: number;
    metric: {
      label: string;
      value: string;
    }[];
    symbol: {
      label: string;
      value: string;
    }[];
    value: {
      label: string;
      value: string;
    }[];
  }[],
  deputyId?: string
) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const memoizedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const params: any = {
          page: page,
        };

        if (fileId && textId) {
          params.uid = fileId;
          params.textId = textId;
        } else if (deputyId) {
          params.deputyId = deputyId;
        }

        if (filters && filters.some((filter) => filter || filter || filter)) {
          params.filters = JSON.stringify(filters);
        }

        const response = await axios.get(
          `https://api.oparl-app.com/textprovider/amendments`,
          //`http://localhost:1080/amendments`,

          { params }
        );
        setData(response.data);
        setError(null);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    if ((fileId && textId) || deputyId) {
      fetchData();
    } else {
      setData([]);
      setLoading(false);
    }
  }, [fileId, textId, memoizedFilters, page, deputyId, filters]);

  return { data, loading, error };
};

export default useAmendments;
