import React from "react";
import styled from "styled-components";

interface ModuleProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
  flexGrow?: number;
  alignItems?: string;
  gap?: number;
}

const Container = styled.div<{
  flexGrow?: number;
  alignItems?: string;
  gap?: number;
}>`
  display: flex;
  flex-direction: column;
  flex: ${({ flexGrow }) => flexGrow || 1} 0 0;
  padding: 28px;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  gap: ${({ gap }) => gap || 10}px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  height: fit-content;
`;

const Module: React.FC<ModuleProps> = ({
  children,
  flexGrow,
  alignItems,
  gap,
}) => {
  return (
    <Container flexGrow={flexGrow} alignItems={alignItems} gap={gap}>
      {children}
    </Container>
  );
};

export default Module;
