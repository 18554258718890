import PageContainer from "../components/containers/PageContainer";
import styled from "styled-components";
import Module from "../components/containers/Module";
import chartmaker from "../assets/img/illu/chartImg.png";
import SearchBar from "../components/search/searchBar";
import Button from "../components/cta/Button";
import userDefaultSVG from "../assets/img/icons/userDefaultSVG.svg";
import Text from "../components/text/Text";
import useAuth from "../hooks/useAuth";

const AccountHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  width: 100%;
  gap: 30px;
`;

const UserProfilePic = styled.img`
  border-radius: 150px;
  display: flex;
  align-items: center;
`;

const SearchContainer = styled.div`
  width: 600px;
`;
const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #3c3c3d;
  font-family: "Sharp Sans";
`;

const AccountInformations = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const SubTitle = styled.div`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  text-transform: capitalize;
`;

const InfosContainer = styled.div`
  display: flex;
  width: 201px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Account = () => {
  const { user, signout } = useAuth();
  return (
    <PageContainer>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <AccountHero>
        <Module gap={30}>
          <Title>Mon compte</Title>
          <Module>
            <AccountInformations>
              <UserProfilePic src={userDefaultSVG} />
              <InfosContainer>
                <SubTitle>{user?.username}</SubTitle>
                <Text>Compte early access</Text>
                <Text>Gratuit</Text>
              </InfosContainer>
            </AccountInformations>
          </Module>
          <ButtonContainer>
            <Button typeButton="primary" onClick={signout}>
              Se deconnecter
            </Button>
          </ButtonContainer>
        </Module>
      </AccountHero>
    </PageContainer>
  );
};

export default Account;
