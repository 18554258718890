import React from "react";
import styled from "styled-components";

interface SimpleProgressBarProps {
  value: number; // Value between 0 and 1
  locked?: boolean;
}

const ProgressBarContainer = styled.div<{ locked?: boolean }>`
  width: 100%;
  height: 6px;
  background: ${({ locked }) =>
    locked ? "#EBEBEB" : "var(--Second-color, #fff7f2)"};
  border-radius: 8px;
  overflow: hidden;
`;

const ProgressBarFiller = styled.div<{ width: number; locked?: boolean }>`
  height: 100%;
  width: ${({ width }) => width}%;
  border-radius: 20px;
  background: ${({ locked }) =>
    locked ? "#EBEBEB" : "var(--Main-color, #ff734f)"};
  transition: width 0.3s ease-in-out;
`;

const SimpleProgressBar: React.FC<SimpleProgressBarProps> = ({
  value,
  locked,
}) => {
  const percentage = Math.min(Math.max(value, 0), 1) * 100; // Ensure value is between 0 and 1

  return (
    <ProgressBarContainer locked={locked}>
      <ProgressBarFiller width={percentage} locked={locked} />
    </ProgressBarContainer>
  );
};

export default SimpleProgressBar;
