import { useParams } from "react-router-dom";
import styled from "styled-components";

import Title from "../../../components/text/Title";
import Text from "../../../components/text/Text";
import speechesImg from "../../../assets/img/illu/speeches.png";
const Modules = styled.div`
  display: flex;
  width: 100%;
  height: 696px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const Module = styled.div`
  display: flex;
  height: 385px;
  padding: 28px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  gap: 20px;
`;

const ImageContainer = styled.div`
  position: relative;
  margin-top: 20px;
  width: 50%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 100%
    );
    pointer-events: none;
  }
`;

const SpeechesImg = styled.img`
  width: 100%;
  object-fit: cover;
`;

const Speeches = () => {
  const { fileId } = useParams<{ fileId: string }>();
  return (
    <Modules>
      <Module>
        <Title type="h2">Toutes les discussions</Title>
        <Text>
          Retrouvez toutes les discussions sur le projet de loi dans la
          prochaine version de Parabole.
        </Text>
        <ImageContainer>
          <SpeechesImg src={speechesImg} alt="speeches" />
        </ImageContainer>
      </Module>
    </Modules>
  );
};

export default Speeches;
