import { LucideArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
interface BackButtonProps {}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const BackButton: React.FC<BackButtonProps> = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <ButtonContainer>
      <LucideArrowLeft size={20} onClick={handleClick} />
    </ButtonContainer>
  );
};

export default BackButton;
