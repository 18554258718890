import { useState, useEffect } from "react";
import axios from "axios";
import { MAIN_URL } from "../config";

interface UseFollowResponse {
  isFollowing: boolean;
  toggleFollow: () => Promise<void>;
  error: string | null;
}

const useFollow = (
  fileId: string,
  userId: number,
  type: string
): UseFollowResponse => {
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const toggleFollow = async () => {
    try {
      const method = isFollowing ? "DELETE" : "POST";
      const response = await axios({
        method,
        url: `${MAIN_URL}/follow/follow`,
        //url: `http://localhost:1040/follow`,
        data: {
          user_id: userId,
          entity_id: fileId,
          type: type,
        },
      });

      if (response.status === 200) {
        setIsFollowing(!isFollowing);
      }
    } catch (err) {
      setError("An error occurred while toggling follow status");
      console.error("Follow error:", err);
    }
  };

  useEffect(() => {
    const getFollowStatus = async () => {
      try {
        const response = await axios.get(`${MAIN_URL}/follow/follow`, {
          params: {
            user_id: userId,
            entity_id: fileId,
            type: type,
          },
        });
        setIsFollowing(response.data.isFollowing);
      } catch (err) {
        setError("An error occurred while fetching follow status");
        console.error("Follow status error:", err);
      }
    };
    if (userId && fileId) {
      getFollowStatus();
    }
  }, [userId, fileId, type]);

  return { isFollowing, toggleFollow, error };
};

export default useFollow;
