import React from "react";
import styled from "styled-components";

interface TitleProps {
  children: React.ReactNode;
  type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  className?: string;
  color?: string;
  margin?: string;
}
const TitleHero = styled.span<TitleProps>`
  color: #000;
  font-family: "Sharp Sans";
  //font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  ${(props) =>
    props.type === "h1" &&
    `
    font-size: 24px;
  `}
  ${(props) =>
    props.type === "h2" &&
    `
    font-size: 18px;
  `}
  ${(props) =>
    props.type === "h3" &&
    `
    font-size: 14px;
  `}
`;
const Title: React.FC<TitleProps> = ({ type, children }) => {
  return <TitleHero type={type}>{children}</TitleHero>;
};

export default Title;
