import { useOutletContext } from "react-router-dom";
import styled from "styled-components";

import Title from "../../../components/text/Title";
import Text from "../../../components/text/Text";
import ProgressBar from "../../../components/cta/ProgressBar";
import LineChartTest from "../../../components/charts/LineChartTest";
//import data from "../../data.json";
import Metric from "../../../components/text/Metric";
import useChart from "../../../hooks/useChart";
import LineChart from "../../../components/charts/LineChart";
import Loader from "../../../components/interaction/Loader";
import useCount from "../../../hooks/useCount";
import Tooltip from "../../../components/cta/Tooltip";
import useProfil from "../../../hooks/useProfil";
import Module from "../../../components/containers/Module";

interface OutletContext {
  deputyId: string;
  data: any;
}

const Modules = styled.div`
  display: flex;
  width: 100%;
  height: 696px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const Meta = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-self: stretch;
`;

const MetaLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const MetaMetric = styled.div`
  color: #969696;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const MetaValue = styled.div`
  color: #232129;
  text-align: right;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const LineChartContainer = styled.div`
  width: 99%;
  height: 270px;
`;

const MetricContainer = styled.div`
  display: flex;
  padding: 20px 0px;
  align-items: flex-end;
  align-self: stretch;
  & > *:not(:first-child) {
    content: "";
    border-left: 1px solid var(--Main-stroke-1, #ebebeb);
  }
`;

const MainMetric = styled.span`
  color: #232129;
  padding-right: 20px;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const OverviewProfil = () => {
  const { deputyId, data } = useOutletContext<OutletContext>();
  console.log("data overview", data);
  const {
    data: datachart,
    loading: loadingchart,
    error: errorchart,
  } = useChart(undefined, deputyId);

  /*
  if (loading) {
    return <Loader />;
  } */

  // Add null check for data
  if (!data) {
    return null; // or return a placeholder/error message component
  }
  return (
    <Modules>
      <Module flexGrow={1}>
        <Title type="h2">En bref</Title>
        <Meta>
          <MetaLine>
            <MetaMetric>Groupe parlementaire</MetaMetric>
            <MetaValue>{data.groupName}</MetaValue>
          </MetaLine>
          <MetaLine>
            <MetaMetric>Commission</MetaMetric>
            <MetaValue>{data.commission}</MetaValue>
          </MetaLine>
          <MetaLine>
            <MetaMetric>Age</MetaMetric>
            <MetaValue>{data.age}</MetaValue>
          </MetaLine>
          <MetaLine>
            <MetaMetric>Département</MetaMetric>
            <MetaValue>{data.department}</MetaValue>
          </MetaLine>
          <MetaLine>
            <MetaMetric>Circonscription</MetaMetric>
            <MetaValue>{data.circonscription}</MetaValue>
          </MetaLine>
          <MetaLine>
            <MetaMetric>Profession</MetaMetric>
            <MetaValue>{data.job}</MetaValue>
          </MetaLine>
        </Meta>
      </Module>

      <Module>
        <Title type="h2">Amendements</Title>
        {loadingchart ? (
          <Loader />
        ) : (
          <LineChartContainer>
            <LineChart data={datachart} />
          </LineChartContainer>
        )}
      </Module>
    </Modules>
  );
};

export default OverviewProfil;
