import { useState, useEffect } from "react";
import { MAIN_URL } from "../../config";
import axios from "axios";
import { DropdownProps } from "../../types/Dropdown";

interface Response {
  items: DropdownProps;
}

interface UseStateInfoResponse {
  data: Response | null;
  loading: boolean;
  error: string | null;
}

const useMetrics = (): UseStateInfoResponse => {
  const [data, setData] = useState<Response | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${MAIN_URL}filtersprovider/getfilters`
          // `http://localhost:1180/getfilters`
        );
        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return { data, loading, error };
};
export default useMetrics;
