import { format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";

const formatDateToFrench = (dateString: string): string => {
  try {
    const date = parseISO(dateString); // Parse the ISO date string
    return format(date, "d MMMM yyyy", { locale: fr }); // Format the date in French
  } catch (error) {
    console.error("Invalid date string:", dateString, error);
    return "Invalid date";
  }
};

export default formatDateToFrench;
