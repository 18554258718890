import React, { lazy, Suspense } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import dynamicIconImports from "lucide-react/dynamicIconImports";

// Define an interface for the props
interface MenuFieldProps {
  iconSrc: string;
  text?: string;
  link: string;
  name: keyof typeof dynamicIconImports;
  isHovered: boolean;
}

const MenuFieldHero = styled(Link)<{ isHovered: boolean }>`
  display: flex;
  height: 41px;
  padding-left: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 17px;
`;

/*

const Icon = styled.img`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
`;*/

const Text = styled.span<{ isHovered: boolean }>`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.1s ease-in-out, width 0.1s ease-in-out;
  text-align: left;
  @media (max-width: 1450px) {
    width: ${({ isHovered }) => (isHovered ? "auto" : "0")};
    visibility: ${({ isHovered }) => (isHovered ? "visible" : "hidden")};
  }
`;
const IconContainer = styled.div<{ isHovered: boolean }>`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
`;

const MenuField: React.FC<MenuFieldProps> = ({
  text,
  iconSrc,
  link,
  name,
  isHovered,
}) => {
  const LucideIcon = lazy(dynamicIconImports[name]);
  return (
    <MenuFieldHero to={link} isHovered={isHovered}>
      <Content>
        <IconContainer isHovered={isHovered}>
          <Suspense fallback={<div>.</div>}>
            <LucideIcon size={18} color="#3C3C3D" />
          </Suspense>
        </IconContainer>
        <Text isHovered={isHovered}>{text}</Text>
      </Content>
    </MenuFieldHero>
  );
};

export default MenuField;
