import React from "react";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

interface LinkTextProps {
  children: React.ReactNode;
  to: string;
  type?: string;
}
const LinkTextHero = styled(Link)<LinkTextProps>`
  color: #ff6107;
  text-decoration: none;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.type === "primary" ? "700" : "500")};
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.14px;
`;

const LinkText: React.FC<LinkTextProps> = ({ children, to, type }) => {
  return (
    <LinkTextHero to={to} type={type}>
      {children}
    </LinkTextHero>
  );
};

export default LinkText;
