import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import searchIcon from "../../../assets/img/icons/search.png";
import Collapser from "../../../components/nav/Collasper";
import useText from "../../../hooks/useText";
import { useOutletContext } from "react-router-dom";
import NoText from "../../../components/text/NoText";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../../components/interaction/Loader";
interface OutletContext {
  rank: number;
  title: string;
  subtitle: string;
  textId: string;
  stageFileId: string;
  fileId: string;
}

interface ChapterProps {
  number: number;
}
const HtmlContainer = styled.div`
  iframe {
    width: 100%;
    border: none;
    height: auto;
    min-height: 500px;
    overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const Modules = styled.div`
  display: flex;
  width: 100%;
  height: 1100px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const Module = styled.div`
  display: flex;
  padding: 0px 28px 26px 28px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 18px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fff;
  overflow-y: scroll;
  width: 100%;
`;

const TopText = styled.div`
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
`;

const Search = styled.button`
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #fff;
  border: 1px solid var(--Main-stroke-1, #ebebeb);

  img {
    width: 186x;
    height: 16px;
  }
`;

const TextTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  align-self: stretch;
  padding-bottom: 25px;
`;

const TitleNumber = styled.span`
  color: #232129;

  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TitleString = styled.span`
  max-width: 663.896px;
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ChapterTitle = styled.div<ChapterProps>`
  display: ${(props) => (props.number > 0 ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  gap: 13px;
  align-self: stretch;
`;

const ChapterNumber = styled.span`
  color: #232129;

  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ChapterName = styled.span`
  width: 663.896px;
  height: 50.581px;
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TextHandler = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const { user } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);
  const { rank, title, subtitle, textId, stageFileId } =
    useOutletContext<OutletContext>();
  const { data, loading, error } = useText(
    fileId || "",
    rank,
    title,
    subtitle,
    stageFileId,
    user.user_id
  );
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!textId) {
    return <NoText name="file-text" />;
  }
  return (
    <Modules>
      <Module>
        <TopText>
          <Search>
            <img src={searchIcon} alt="Search" />
          </Search>
        </TopText>
        {data ? (
          data.TextSection ? (
            data.TextSection.map((section) => (
              <div key={section.number}>
                <TextTitle>
                  <TitleNumber>
                    {section.title !== "Default Section" ? section.title : ""}
                  </TitleNumber>
                  <TitleString>{section.sub_title}</TitleString>
                </TextTitle>
                {section.Chapter.map((Chapter) => (
                  <div key={Chapter.number}>
                    <ChapterTitle number={Chapter.number}>
                      <ChapterNumber> {Chapter.name}</ChapterNumber>
                      <ChapterName>{Chapter.title}</ChapterName>
                    </ChapterTitle>
                    {Chapter.Article.map((article) => (
                      <Collapser
                        key={article.number}
                        title={article.title}
                        paragraph={article.Paragraph}
                      />
                    ))}
                  </div>
                ))}
              </div>
            ))
          ) : data.html ? (
            <HtmlContainer>
              <iframe
                srcDoc={data.html}
                title="Law text content"
                scrolling="no"
                onLoad={(e) => {
                  //Adjust iframe width
                  const iframe = e.target as HTMLIFrameElement;
                  iframe.style.width = "1000px";

                  // Adjust height to take 100% of the document height
                  iframe.style.height =
                    iframe.contentWindow?.document.documentElement
                      .scrollHeight + "px";
                }}
                // Disable scrolling
                style={{
                  overflow: "hidden",
                }}
                // Hide scrollbar
                className="hide-scrollbar"
              />
            </HtmlContainer>
          ) : (
            <p>No HTML in response data</p>
          )
        ) : (
          <p>No data available</p>
        )}
      </Module>
    </Modules>
  );
};

export default TextHandler;
