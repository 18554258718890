import axios from "axios";
import { ACCESS_URL } from "../config";

const TOKEN_KEY = "ttoV8lZWOCebCulUOfrSuQG18Lt8f6hA";

const AxiosDataFetch = () => {
  const config = {
    headers: {
      Access: TOKEN_KEY,
    },
  };
  return axios.post(`${ACCESS_URL}jwt`, {}, config);
};

export const GetJWT = async (
  setTokenUser: React.Dispatch<React.SetStateAction<string | null>>
) => {
  console.log("Getting JWT credentials...");
  const jwt = await AxiosDataFetch()
    .then((response) => response.data)
    .catch((e) => {
      console.log(`JWT error ${e}`);
      return null;
    });
  setTokenUser(jwt);
};

export const CreateJWT = async (
  userID: number,
  setTokenUser: React.Dispatch<React.SetStateAction<string | null>>
) => {
  console.log("Creating JWT credentials...");
  const config = {
    headers: {
      Access: TOKEN_KEY,
    },
  };
  const data = {
    user_id: userID,
  };

  const jwt = await axios
    .post(`${ACCESS_URL}jwt`, data, config)
    .then((response) => response.data)
    .catch((e) => {
      console.log(`JWT creation error ${e}`);
      return null;
    });

  setTokenUser(jwt);
};
