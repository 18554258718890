import { useOutletContext } from "react-router-dom";
import styled from "styled-components";

import Title from "../../../components/text/Title";
import Text from "../../../components/text/Text";
import ProgressBar from "../../../components/cta/ProgressBar";
import LineChartTest from "../../../components/charts/LineChartTest";
//import data from "../../data.json";
import Metric from "../../../components/text/Metric";
import useChart from "../../../hooks/useChart";
import LineChart from "../../../components/charts/LineChart";
import Loader from "../../../components/interaction/Loader";
import useCount from "../../../hooks/useCount";
import Tooltip from "../../../components/cta/Tooltip";

interface OutletContext {
  fileId: string;
}

const Modules = styled.div`
  display: flex;
  width: 100%;
  height: 696px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const Module = styled.div`
  display: flex;
  height: 385px;
  padding: 28px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const FileSteps = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const LineChartContainer = styled.div`
  width: 99%;
  height: 270px;
`;

const MetricContainer = styled.div`
  display: flex;
  padding: 20px 0px;
  align-items: flex-end;
  align-self: stretch;
  & > *:not(:first-child) {
    content: "";
    border-left: 1px solid var(--Main-stroke-1, #ebebeb);
  }
`;

const MainMetric = styled.span`
  color: #232129;
  padding-right: 20px;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Overview = () => {
  const { fileId } = useOutletContext<OutletContext>();
  const { data, loading, error } = useChart(fileId);
  const {
    data: countData,
    loading: countLoading,
    error: countError,
  } = useCount(fileId);

  if (loading) {
    return <Loader />;
  }

  // Add null check for data
  if (!data || !data[0].data) {
    return null; // or return a placeholder/error message component
  }
  console.log("data", data[0].data);
  return (
    <Modules>
      <Tooltip>
        <Module>
          <Title type="h2">Vue d'ensemble</Title>
          <FileSteps>
            <Title type="h3">Première lecture à l'Assemblée</Title>
            <Text>Dernière étape : --</Text>
            <ProgressBar completion={80} locked={true} />
          </FileSteps>
          <FileSteps>
            <Title type="h3">Deuxième lecture à l'Assemblée</Title>
            <Text>Dernière étape : --</Text>
            <ProgressBar completion={80} locked={true} />
          </FileSteps>
        </Module>
      </Tooltip>
      <Module>
        <Title type="h2">Amendements</Title>
        <LineChartContainer>
          <MetricContainer>
            {countData && (
              <>
                <MainMetric>{countData.data.total}</MainMetric>

                <Metric
                  metricName={"Acceptés"}
                  metricValue={countData.data.accepted}
                />
                <Metric
                  metricName={"Rejetés"}
                  metricValue={countData.data.rejected}
                />
                <Metric
                  metricName={"Non décidés"}
                  metricValue={countData.data.notDecided}
                />
              </>
            )}
          </MetricContainer>
          <LineChart data={data} />
        </LineChartContainer>
      </Module>
    </Modules>
  );
};

export default Overview;
