import React, { useState } from "react";
import styled from "styled-components";

interface TooltipProps {
  text?: string;
  children: React.ReactNode;
  disabled?: boolean;
}

const HoverOverlay = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #232129;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  font-family: "Sharp Sans";

  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #232129;
  }
`;

const InteractionWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Tooltip: React.FC<TooltipProps> = ({ text, children, disabled }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  if (!text) {
    text = "Cette fonctionnalité n'est pas disponible";
  }
  if (!disabled) {
    disabled = false;
  }
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <InteractionWrapper
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && <HoverOverlay>{text}</HoverOverlay>}
    </InteractionWrapper>
  );
};

export default Tooltip;
