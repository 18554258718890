import React from "react";
import styled, { css, keyframes } from "styled-components";
import ProfilPic from "../img/profilPic";
import { Link } from "react-router-dom";
interface CardProps {
  fullName?: string;
  groupNameShort: string;
  deputyId: string;
  firstName: string;
  lastName: string;
  amendmentsCount: number;
  amendmentsAccepted: number;
}

const CardContainer = styled(Link)`
  display: flex;
  text-decoration: none;
  width: 136px;
  height: 200px;
  padding: 18px 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid #f0f1f3;
  background: #fff;
  backdrop-filter: blur(7.5px);
  &:hover {
    cursor: pointer;
    border: 1px solid #ff734f;
  }
`;

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 0;
`;

const NameText = styled.p`
  display: block;
  width: 136px;
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  margin: 0;
  padding: 0;
`;

const GroupText = styled.p`
  display: block;
  color: #ff734f;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  padding: 0;
`;

const Stats = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  align-self: stretch;
  padding: 0px 10px;
`;

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  padding: 0px;
  gap: px;
`;

const MetricName = styled.p`
  display: block;
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
`;

const MetricValue = styled.p`
  display: block;
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;
`;

const Card: React.FC<CardProps> = ({
  firstName,
  lastName,
  groupNameShort,
  deputyId,
  amendmentsCount,
  amendmentsAccepted,
}) => {
  return (
    <CardContainer key={deputyId} to={`/people/${deputyId}/overview`}>
      <ProfilPic deputyId={deputyId} size={61} />
      <Infos>
        <NameText>{firstName}</NameText>
        <NameText>{lastName}</NameText>
        <GroupText>{groupNameShort}</GroupText>
      </Infos>
      <Stats>
        <Metric>
          <MetricName>Amendements</MetricName>
          <MetricValue>{amendmentsCount}</MetricValue>
        </Metric>
        <Metric>
          <MetricName>Adoptés</MetricName>
          <MetricValue>{amendmentsAccepted}</MetricValue>
        </Metric>
      </Stats>
    </CardContainer>
  );
};

export default Card;
