import React from "react";
import styled from "styled-components";

interface MetricProps {
  metricName?: string;
  metricValue?: number;
}
const MetricHero = styled.div<MetricProps>`
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  align-items: flex-start;
`;
const Name = styled.span<MetricProps>`
  color: #000;

  text-align: center;
  font-family: "Sharp Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Value = styled.span<MetricProps>`
  color: #232129;

  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const Metric: React.FC<MetricProps> = ({ metricName, metricValue }) => {
  return (
    <MetricHero>
      <Name>{metricName}</Name>
      <Value>{metricValue}</Value>
    </MetricHero>
  );
};

export default Metric;
