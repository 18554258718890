import React from "react";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

interface TextProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
  margin?: string;
}
const TextHero = styled.span<TextProps>`
  color: #6e6e6e;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
`;
const Text: React.FC<TextProps> = ({ children }) => {
  return <TextHero>{children}</TextHero>;
};

export default Text;
