import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useAmendmentDetails from "../../../hooks/useAmendmentDetails";
import Arrow from "../../../assets/img/icons/arrow-1-w.png";
import Category from "../../../components/text/Category";
import Button from "../../../components/cta/Button";
import SimpleProgressBar from "../../../components/charts/SimpleProgressBar";
import ProfilPic from "../../../components/img/profilPic";
import formatDateToFrench from "../../../utils/formatDateToFrench";
import cleanHtmlText from "../../../utils/cleanHtmlText";
import Loader from "../../../components/interaction/Loader";

const Modules = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
  height: fit-content;
`;

const Module = styled.div<{ flexGrow?: number }>`
  display: flex;
  padding: 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  height: fit-content;
  flex: ${({ flexGrow }) => flexGrow || 1} 0 0;
`;

const BackButton = styled.div`
  display: flex;
  width: 24px;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 10px;
  cursor: pointer;
`;

const ArrowButton = styled.img`
  width: 16.026px;
  height: 13.261px;
  rotate: 180deg;
`;

const AmendmentContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  padding-bottom: 10px;
`;

const Title = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: var(--Main-color, #ff734f);
  font-family: "Sharp Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
`;

const SubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const SubTitleText = styled.span`
  color: #000;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px; /* 121.429% */
`;

const Date = styled.span`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
`;

const Ammend = styled.div`
  text-align: left;
  & p {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const AmmendTextTitle = styled.span`
  color: #000;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
`;

const AmmendText = styled.p`
  color: #000;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
`;

const Caracteristics = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  width: 100%;
`;

const Caracteristic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  & p {
    margin: 0;
  }
`;

const AmendtsDetails: React.FC = () => {
  const { amendtsId } = useParams<{ amendtsId: string }>();
  const { data, loading, error } = useAmendmentDetails(amendtsId);

  if (loading) {
    return <Loader />;
  }

  return (
    <Modules>
      <Module flexGrow={2}>
        <BackButton onClick={() => window.history.back()}>
          <ArrowButton src={Arrow} />
        </BackButton>
        <AmendmentContent>
          <Title>{data?.Title}</Title>
          <SubTitle>
            <SubTitleText>{"Amendement n°" + data?.IdOrder}</SubTitleText>
            <Category>{data?.Status}</Category>
            <Date>
              {"Déposé le " + formatDateToFrench(data?.CreatedAt || "")}
            </Date>
          </SubTitle>
          <Ammend>
            <AmmendTextTitle>Exposé sommaire</AmmendTextTitle>
            <AmmendText
              dangerouslySetInnerHTML={{
                __html: cleanHtmlText(data?.ContentSummary || ""),
              }}
            />
          </Ammend>
          <Ammend>
            <AmmendTextTitle>{data?.Spot}</AmmendTextTitle>
            <AmmendTextTitle>Dispositif</AmmendTextTitle>
            <AmmendText>{data?.Content}</AmmendText>
          </Ammend>
          <Button typeButton="secondary">
            Voir la portion du texte modifiée
          </Button>
        </AmendmentContent>
      </Module>
      <Module flexGrow={1}>
        <Caracteristics>
          <Caracteristic>
            <AmmendTextTitle>Probabilité d'obstuction</AmmendTextTitle>
            <SimpleProgressBar locked={true} value={0.5} />
          </Caracteristic>
          <Caracteristic>
            <AmmendTextTitle>Probabilité de recevabilité</AmmendTextTitle>
            <SimpleProgressBar locked={true} value={0.4} />
          </Caracteristic>
          <Caracteristic>
            <AmmendTextTitle>Proposé par</AmmendTextTitle>
            <ProfilPic deputyId={data?.DeputyId} size={40} />
          </Caracteristic>
          <Caracteristic>
            <AmmendTextTitle>Amendements similaires</AmmendTextTitle>
            <AmmendText>NC</AmmendText>
          </Caracteristic>
          <Button typeButton="secondary">
            Voir tous les amendements similaires
          </Button>
          <Button typeButton="secondary">Défendre cet amendement</Button>
        </Caracteristics>
      </Module>
    </Modules>
  );
};

export default AmendtsDetails;
