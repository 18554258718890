import React from "react";
import styled from "styled-components";

interface ToggleProps {
  isOn: boolean;
  onToggle: () => void;
}

const ToggleWrapper = styled.div<{ isOn: boolean }>`
  display: flex;
  width: 35px;
  height: 18px;
  background-color: ${(props) => (props.isOn ? "#FF734F" : "#EBEBEB")};
  border-radius: 20px;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-items: center;
`;

const ToggleButton = styled.div<{ isOn: boolean }>`
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
  transform: translateX(${(props) => (props.isOn ? "17px" : "0")});
`;

const Toggle: React.FC<ToggleProps> = ({ isOn, onToggle }) => {
  return (
    <ToggleWrapper isOn={isOn} onClick={onToggle}>
      <ToggleButton isOn={isOn} />
    </ToggleWrapper>
  );
};

export default Toggle;
