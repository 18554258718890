import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useAlert from "../../hooks/useAlert";
import { Alert } from "../../types/Alerts";
import Button from "../../components/cta/Button";
import BackButton from "../../components/nav/BackButton";
import PageContainer from "../../components/containers/PageContainer";
import SearchBar from "../../components/search/searchBar";
import Loader from "../../components/interaction/Loader";

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  border-radius: 18px;
`;

const Header = styled.div`
  display: flex;
  height: 64px;
  padding: 0px 30px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 18px 18px 0px 0px;
  background: #fcfcfc;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
  align-items: center;
`;

const TitleHeader = styled.div`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const InputHeader = styled.div`
  color: #3c3c3d
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  border: none;
  padding: 0;

  &:focus-visible {
    outline: none;
  }
`;

const Details = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  gap: 8px;
  padding: 20px 30px;
`;

const SubText = styled.div`
  color: #828283;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;
  border-top: 1px solid var(--Main-stroke-1, #ebebeb);
  padding: 30px 60px;
  position: relative;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const Notification = styled.div`
  display: flex;
  width: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const NotificationCircleBegin = styled.div`
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #232129;
`;

const NotificationCircle = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  padding: 0px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--Main-color, #ff734f);
  p {
    color: #fff;
    text-align: center;
    font-family: "Sharp Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
  }
`;

const ItemText = styled.div`
  text-align: left;
  color: #6e6e6e;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const VerticalLine = styled.div`
  position: absolute;
  left: 69px;
  top: 40px;
  bottom: 50px;
  width: 2px;
  background-color: #d9d9d9;
  z-index: -1;
`;

const Footer = styled.div`
  display: flex;
  padding: 30px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const SearchContainer = styled.div`
  width: 600px;
`;

const Summary = () => {
  const { alertId } = useParams<{ alertId: string }>();
  const { getAlert, deleteAlert, loading } = useAlert();
  const [alert, setAlert] = useState<Alert | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (alertId) {
      getAlert(alertId).then((data) => {
        if (data) {
          setAlert(data);
          console.log("Alert:", data);
        }
      });
    }
  }, [alertId, getAlert]);

  const handleModify = () => {
    if (alert) {
      navigate(`/alerts/config/${alertId}`, { state: { alert } });
    }
  };

  const handleDelete = async () => {
    if (alertId) {
      try {
        await deleteAlert(alertId);
        navigate("/alerts"); // Navigate back to the alerts list after deletion
      } catch (error) {
        console.error("Error deleting alert:", error);
        // You might want to show an error message to the user here
      }
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <Loader page={true} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <SummaryContainer>
        <Header>
          <BackButton />
          <TitleHeader>Retour à la liste des alertes</TitleHeader>
        </Header>
        <Details>
          <Name>
            <InputHeader>{alert?.name}</InputHeader>
            <SubText>Mot clé surveillé : {alert?.keyWord}</SubText>
          </Name>
          <Timeline>
            {alert?.triggeredByNodes?.[0].foundIn !== "no text" ? (
              <>
                <Item>
                  <Notification>
                    <VerticalLine />
                    <NotificationCircleBegin />
                  </Notification>
                  <ItemText>Création de l'alerte</ItemText>
                </Item>
                {alert?.triggeredByNodes?.map((node, index) => (
                  <Item key={index}>
                    <Notification>
                      <VerticalLine />
                      <NotificationCircle>
                        <p>{node.count}</p>
                      </NotificationCircle>
                    </Notification>
                    <ItemText style={{ fontWeight: 700 }}>{node.date}</ItemText>
                    <ItemText>{node.foundIn}</ItemText>
                  </Item>
                ))}
              </>
            ) : (
              <Item>
                <Notification>
                  <NotificationCircleBegin />
                </Notification>
                <ItemText>Création de l'alerte</ItemText>
              </Item>
            )}
          </Timeline>
          <Footer>
            <Button typeButton="secondary" onClick={handleModify}>
              Modifier
            </Button>
            <Button typeButton="secondary" onClick={handleDelete}>
              Supprimer
            </Button>
          </Footer>
        </Details>
      </SummaryContainer>
    </PageContainer>
  );
};

export default Summary;
