import React from "react";
import styled from "styled-components";
import ProfilPic from "../img/profilPic";
import Modules from "../containers/Modules";

interface SpeechUnitProps {
  deputyId: string;
  fullName: string;
  typeMeeting: string;
  text: string;
}

const SpeechContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  border-radius: 18px;
  max-width: 100%;
  background-color: #f9f9f9;
  box-sizing: border-box;
`;

const ProfilPicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpeechContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const Title = styled.span`
  color: #ff734f;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const Text = styled.span`
  color: #6e6e6e;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  padding: 0;
  margin: 0;
`;

const SpeechUnit: React.FC<SpeechUnitProps> = ({
  deputyId,
  fullName,
  typeMeeting,
  text,
}) => {
  const titleText = `${fullName} est intervenu(e) en ${typeMeeting}`;
  return (
    <SpeechContainer>
      <ProfilPicContainer>
        <ProfilPic deputyId={deputyId} size={37} />
      </ProfilPicContainer>
      <SpeechContent>
        <Title>{titleText}</Title>
        <Text>{text}</Text>
      </SpeechContent>
    </SpeechContainer>
  );
};

export default SpeechUnit;
