import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface TableProps {
  columns: string[];
  data: Array<{ [key: string]: any }>;
  actionLink?: string;
  customRenderers?: {
    [key: string]: (value: any, row: any) => React.ReactNode;
  };
  isLoading?: boolean;
  emptyDateMessage?: string;
}

const TableContainer = styled.div`
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  border-radius: 18px;
  background: #fff;
  overflow: hidden;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background: #fcfcfc;
  border-radius: 18px 18px 0 0;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const TableHeaderCell = styled.th`
  font-family: "Sharp Sans";
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: #3c3c3d;
  padding: 20px 28px;
`;

const TableBody = styled.tbody`
  padding: 28px 28px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ebebeb;
`;

const TableCell = styled.td`
  padding: 20px 28px;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-weight: 700;
  color: #3c3c3d;
  text-align: left;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ArrowLink = styled(Link)`
  text-decoration: none;
  color: #3c3c3d;
  font-weight: bold;
`;

const LoadingMessage = styled.div`
  padding: 20px;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  color: #3c3c3d;
`;

const Table: React.FC<TableProps> = ({
  columns,
  data,
  actionLink,
  customRenderers,
  isLoading,
  emptyDateMessage,
}) => {
  if (isLoading) {
    return (
      <TableContainer>
        <LoadingMessage>Loading...</LoadingMessage>
      </TableContainer>
    );
  }

  if (!data || data.length === 0) {
    return (
      <TableContainer>
        {emptyDateMessage ? (
          <LoadingMessage>{emptyDateMessage}</LoadingMessage>
        ) : (
          <LoadingMessage>No data available.</LoadingMessage>
        )}
      </TableContainer>
    );
  }
  var pathLink = "";

  const getPathLink = (actionLink: string, fileId: string) => {
    if (actionLink === "AmendmentId") {
      pathLink = `/file/${fileId}/amendts/`;
      return pathLink;
    } else if (actionLink === "DeputyId") {
      pathLink = ``;
      return pathLink;
    }
  };

  return (
    <TableContainer>
      <StyledTable>
        <TableHeader>
          <tr>
            {columns.map((column) => (
              <TableHeaderCell key={column}>{column}</TableHeaderCell>
            ))}
            {actionLink && <TableHeaderCell></TableHeaderCell>}
          </tr>
        </TableHeader>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell key={column}>
                  {customRenderers && customRenderers[column]
                    ? customRenderers[column](row[column], row)
                    : row[column]}
                </TableCell>
              ))}
              {actionLink && (
                <TableCell>
                  <ArrowLink
                    to={`${getPathLink(actionLink, row.FileId)}${
                      row[actionLink]
                    }`}
                  >
                    →
                  </ArrowLink>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default Table;
