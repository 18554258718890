import { useState } from "react";
import styled from "styled-components";
import useFile from "../../hooks/useFile";
import FileSum from "../../components/blocks/fileSum";
import PageContainer from "../../components/containers/PageContainer";
import SearchBar from "../../components/search/searchBar";
import Loader from "../../components/interaction/Loader";
import PageNavigator from "../../components/nav/PageNavigator";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 20px;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const SearchContainer = styled.div`
  width: 600px;
`;

const PageNavigatorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Files = () => {
  const [pageSelected, setPageSelected] = useState(1);
  const { data, loading, error } = useFile(pageSelected);
  console.log("Hook data:", data);

  if (loading) {
    return (
      <PageContainer>
        <Loader page={true} />
      </PageContainer>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!data || !data.Files) {
    return <p>No data</p>;
  }

  return (
    <PageContainer>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <Container>
        {data.Files.map((file) => (
          <FileSum
            uid={file.uid}
            name={file.name}
            wording={file.wording}
            status={file.status}
            stageFile={file.stage_file_title}
            stateFile={file.state_file_title}
          />
        ))}
      </Container>
      <PageNavigator
        pageSelected={pageSelected}
        pageList={Array.from({ length: data.total_pages }, (_, i) => i + 1)}
        setPageSelected={setPageSelected}
      />
    </PageContainer>
  );
};

export default Files;
