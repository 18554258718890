import { useState, useCallback } from "react";
import useAuth from "./useAuth";
import axios from "axios";
import { MAIN_URL } from "../config";
import { Alert as AlertType } from "../types/Alerts";

const useAlert = () => {
  const [data, setData] = useState<AlertType[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const createAlert = async (alertData: Partial<AlertType>) => {
    setLoading(true);
    setError(null);
    console.log(alertData.keyWord);
    console.log(alertData.filters);
    try {
      const url = `${MAIN_URL}/follow/custom-keyword`;
      //const url = "http://localhost:1040/custom-keyword";
      const response = await axios.post(url, {
        method: "POST",
        key_word: alertData.keyWord,
        entity_id: alertData.entityId,
        entity_type: alertData.entityType,
        name: alertData.name,
        user_id: user.user_id,
        filters: alertData.filters,
      });

      setData(response.data);
      setLoading(false);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      setLoading(false);
    }
  };

  const getAlertList = useCallback(async (): Promise<
    AlertType[] | undefined
  > => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${MAIN_URL}/follow/keywordslist`, {
        params: {
          user_id: user.user_id,
        },
      });
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAlert = useCallback(
    async (usercustomkeyword_id?: string): Promise<AlertType | undefined> => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${MAIN_URL}/follow/keywordsummary`, {
          params: {
            user_id: user.user_id,
            usercustomkeyword_id: usercustomkeyword_id,
          },
        });
        setLoading(false);
        return response.data;
      } catch (err) {
        setLoading(false);
        if (axios.isAxiosError(err) && err.response) {
          setError(err.response.data);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
        return undefined;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateAlert = async (
    usercustomkeyword_id: string,
    alertData: Partial<AlertType>
  ) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.put(`${MAIN_URL}follow/modifykeyword`, {
        key_word: alertData.keyWord,
        entity_id: alertData.entityId,
        entity_type: alertData.entityType,
        name: alertData.name,
        user_id: user.user_id,
        usercustomkeyword_id: usercustomkeyword_id,
      });
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      return undefined;
    }
  };

  const deleteAlert = async (usercustomkeyword_id: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.delete(`${MAIN_URL}/follow/custom-keyword`, {
        params: {
          user_id: user.user_id,
          usercustomkeyword_id: usercustomkeyword_id,
        },
      });
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
      return undefined;
    }
  };

  return {
    createAlert,
    getAlertList,
    getAlert,
    updateAlert,
    deleteAlert,
    loading,
    error,
  };
};

export default useAlert;
