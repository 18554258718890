import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import styled from "styled-components";
import IconCircle from "../../src/assets/img/icons/LogoCircle.png";
import { Pen, BellPlus, FilePen } from "lucide-react";
import SearchBar from "../components/search/searchBar";
import PageContainer from "../components/containers/PageContainer";
import ModalInfo from "../components/nav/ModalInfo";

const HomeHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;
const LogoCircle = styled.img`
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 79.751px;
  height: 80.748px;
`;

const TitleHome = styled.h1`
  color: #202020;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

const ShortCut = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const OptionsHome = styled(Link)`
  display: flex;
  padding: 13px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  width: 130px;
  height: 80px;
  text-decoration: none;
  color: #828283;
  &:hover {
    border-color: #ff734f;
    color: #ff734f;
    svg {
      stroke: #ff734f;
    }
  }

  span {
    font-family: "Sharp Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    text-align: left;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Home = () => {
  const { user, signout } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const handleCampaignClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <PageContainer>
      <HomeHero>
        <LogoCircle src={IconCircle} />
        <TitleHome>Commencez à capter des signaux</TitleHome>
        <ShortCut>
          <OptionsHome to="/files">
            <Pen size={20} color="#828283" />
            <span>Explorez un dossier</span>
          </OptionsHome>
          <OptionsHome to="/alerts">
            <BellPlus size={20} color="#828283" />
            <span>Configurez une alerte</span>
          </OptionsHome>
          <OptionsHome to="#" onClick={handleCampaignClick}>
            <FilePen size={20} color="#828283" />
            <span>Débutez une campagne</span>
          </OptionsHome>
        </ShortCut>
        {showModal && (
          <ModalOverlay>
            <ModalInfo
              title="Fonctionnalité verrouillée"
              description="Ce contenu est bloqué dans la version de Parabole que vous utilisez. Contactez-nous pour plus d'informations."
              onClose={() => setShowModal(false)}
            />
          </ModalOverlay>
        )}
        <SearchContainer>
          <SearchBar />
        </SearchContainer>
      </HomeHero>
    </PageContainer>
  );
};

export default Home;
