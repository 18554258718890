import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Button from "../components/cta/Button";
import ErrorMessage from "../components/errors/ErrorMessage";
import styled from "styled-components";
import Text from "../components/text/Text";
import { LucideArrowLeft } from "lucide-react";

const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  font-family: "Sharp Sans";
  line-height: 24px;
  letter-spacing: 0.14px;
`;

const Container = styled.div`
  display: inline-flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #fcfcfc;
`;

const LoginHero = styled.div`
  display: flex;
  width: 494px;
  padding: 42px 42px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 18px;
  background: #fff;
  align-items: center;
  justify-content: center;
`;

const InputText = styled.input`
  border: none;
  display: flex;
  height: 20px;
  padding: 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--Second-color, #fff7f2);
  width: 100%;
  &:focus {
    outline: none !important;
    border: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
`;

const UserMessage = styled.span`
  font-family: "Sharp Sans";
  font-size: 14px;
  font-weight: 500;
  color: #ff734f;
`;

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const { forgotPassword, isLoading } = useAuth();
  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if (!email) {
      setError("Veuillez entrer votre adresse e-mail");
      return;
    }

    const success = await forgotPassword(email);
    if (success) {
      setMessage(
        "Si un compte existe avec cette adresse e-mail, vous recevrez des instructions pour réinitialiser votre mot de passe."
      );
      setEmail("");
    } else {
      setError("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  };

  return (
    <Container>
      <LoginHero>
        <BackButtonContainer>
          <LucideArrowLeft onClick={() => navigate("/login")} />
        </BackButtonContainer>
        <Title>Mot de passe oublié ?</Title>
        <Text>
          Entrez votre adresse e-mail et nous vous enverrons des instructions
          pour réinitialiser votre mot de passe.
        </Text>

        <Form onSubmit={handleSubmit}>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {message && (
            <UserMessage className="rounded-md bg-green-50 p-4">
              {message}
            </UserMessage>
          )}

          <InputText
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            placeholder="Adresse e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button typeButton="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Envoi en cours..." : "Envoyer les instructions"}
          </Button>
        </Form>
      </LoginHero>
    </Container>
  );
};

export default ForgotPassword;
