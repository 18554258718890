import React from "react";
import styled from "styled-components";

interface ModulesProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const Modules: React.FC<ModulesProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Modules;
