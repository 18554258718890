import { ResponsiveLine } from "@nivo/line";

interface LineProps {
  data: any;
}

const LineChart: React.FC<LineProps> = ({ data }) => {
  if (data.lenght > 0) return null;
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 20, right: 0, bottom: 40, left: 40 }}
      xScale={{ type: "point" }}
      curve="linear"
      enableGridX={false}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      theme={{
        legends: {
          text: {
            fontFamily: "Sharp Sans",
          },
        },

        axis: {
          legend: {
            text: {
              fontFamily: "Sharp Sans",
              fontSize: 14,
            },
          },
        },
        text: {
          fontSize: 11,
          fontFamily: "Sharp Sans",
          fontWeight: 600,
          fill: "#6E6E6E",
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      }}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 25,
        tickValues: data[0].data
          .map((item: any) => item.x)
          .filter((_: any, index: number) => index % 10 === 0),

        //truncateTickAt: 10,

        //tickValues: ["2024-10-01", "2024-10-04", "2024-10-07", "2024-10-10"],
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 4,
      }}
      enableArea={true}
      areaOpacity={0.05}
      gridYValues={4}
      enablePoints={false}
      enableTouchCrosshair={true}
      useMesh={true}
      colors={["#FF734F", "#222222"]}
    />
  );
};

export default LineChart;
