import { useState, useEffect } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";

interface Data {
  total: number;
  accepted: number;
  rejected: number;
  notDecided: number;
}

interface CountData {
  data: Data;
}

interface UseCountDataResponse {
  data: CountData | null;
  loading: boolean;
  error: string | null;
}

const useCount = (fileId?: string): UseCountDataResponse => {
  const [data, setData] = useState<CountData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      console.log("fileId", fileId);
      try {
        const response = await axios.get(`${MAIN_URL}/chartmaker/nodeCount`, {
          params: {
            uid: fileId,
          },
        });
        //const response = await axios.get(`${MAIN_URL}textprovider/files`);
        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error };
};

export default useCount;
