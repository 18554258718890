import React, { useState } from "react";
import styled from "styled-components";
import collapseIcon from "../../assets/img/icons/collapseIcon.png";
import Article from "../legal/Article";

interface Paragraph {
  text: string;
  number: number;
  wordsSaved: string[];
  paragraphId: string;
}

interface CollapserProps {
  title: string;
  paragraph: Paragraph[];
  renderParagraph?: (paragraph: Paragraph) => React.ReactNode;
}

const CollapserHero = styled.div`
  display: flex;
  padding: 15px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const CollapserTitleContainer = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  border: none;
  cursor: pointer;
`;

const CollapserTitle = styled.span`
  color: #000;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

const CollapserIcon = styled.img<{ isCollapsed: boolean }>`
  height: 5px;
  transition: transform 0.2s ease;
  transform: rotate(${(props) => (props.isCollapsed ? "-90deg" : "0deg")});
`;

const ContentCollapsed = styled.div<{ isCollapsed: boolean }>`
  max-height: ${(props) => (props.isCollapsed ? "0px" : "auto")};
  opacity: ${(props) => (props.isCollapsed ? 0 : 1)};
  overflow: hidden;
  transition: max-height 0.1s ease, opacity 0.1s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  overflow: visible;
`;

const Collapser: React.FC<CollapserProps> = ({
  title,
  paragraph,
  renderParagraph,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <CollapserHero>
      <CollapserTitleContainer onClick={toggleCollapse}>
        <CollapserTitle>{title}</CollapserTitle>
        <CollapserIcon src={collapseIcon} isCollapsed={isCollapsed} />
      </CollapserTitleContainer>
      <ContentCollapsed isCollapsed={isCollapsed}>
        {paragraph.map((p, index) => (
          <Article
            key={index}
            rank={index + 1}
            wordsSaved={p.wordsSaved}
            paragraphId={p.paragraphId}
          >
            {p.text}
          </Article>
        ))}
      </ContentCollapsed>
    </CollapserHero>
  );
};

export default Collapser;
