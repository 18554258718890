const cleanHtmlText = (content: string): string => {
  // Create a new DOMParser instance
  const parser = new DOMParser();
  // Parse the content string into a Document
  const doc = parser.parseFromString(content, "text/html");

  // Function to recursively clean text nodes and remove empty elements
  const cleanNode = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      node.textContent = node.textContent?.replace(/(\s|&nbsp;)+$/, "") || "";
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;

      // Remove <br> tags
      if (element.tagName === "BR") {
        element.remove();
        return;
      }

      // Recursively clean child nodes
      Array.from(element.childNodes).forEach(cleanNode);

      // Remove empty <p> tags
      if (element.tagName === "P" && element.innerHTML.trim() === "") {
        element.remove();
      }
    }
  };

  // Clean all child nodes of the document body
  Array.from(doc.body.childNodes).forEach(cleanNode);

  // Serialize the cleaned document back to a string
  return doc.body.innerHTML;
};

export default cleanHtmlText;
