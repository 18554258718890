import React from "react";
import styled from "styled-components";
import Button from "../cta/Button";

// Define an interface for the props
interface ModalInfoProps {
  title: string;
  description: string;
  onClose: () => void;
}

const ModalInfoHero = styled.div`
  position: relative;
  display: flex;
  width: 838.854px;
  padding: 62px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 18px;
  background: #232129;
`;

const Title = styled.span`
  color: var(--White_high, rgba(255, 255, 255, 0.9));
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Sharp Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Description = styled.span`
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
`;

const ModalInfo: React.FC<ModalInfoProps> = ({
  title,
  description,
  onClose,
}) => {
  const handleContactClick = () => {
    window.open("https://calendly.com/slesueur/talk", "_blank");
    onClose();
  };

  return (
    <ModalInfoHero>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button typeButton="primary" onClick={handleContactClick}>
        Nous contacter
      </Button>
    </ModalInfoHero>
  );
};

export default ModalInfo;
