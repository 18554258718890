import React, { lazy, Suspense } from "react";
import styled from "styled-components";
import dynamicIconImports from "lucide-react/dynamicIconImports";

interface NoTextProps {
  name: keyof typeof dynamicIconImports;
}
const NoTextHero = styled.div`
  display: flex;
  margin: auto;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

const NoTextText = styled.div`
  font-family: "Sharp Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: #6e6e6e;
`;

const NoText: React.FC<NoTextProps> = ({ name }) => {
  //console.log("dynamicIconImports[name]:", dynamicIconImports[name]);
  const LucideIcon = lazy(dynamicIconImports["file-text"]);
  return (
    <NoTextHero>
      <Suspense fallback={<div>.</div>}>
        <LucideIcon size={150} color="#6e6e6e" strokeWidth={1} />
      </Suspense>
      <NoTextText>Pas de texte associé à cette étape.</NoTextText>
    </NoTextHero>
  );
};

export default NoText;
