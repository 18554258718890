import React from "react";
import styled from "styled-components";

interface TextProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
  margin?: string;
}

const getStyles = (children: React.ReactNode) => {
  if (children === "Adopté") {
    return {
      textcolor: "#89b97b",
      backgroundColor: "#f5fff2",
      fontWeight: "700",
    };
  } else if (
    children === "Tombé" ||
    children === "Retiré" ||
    children === "Rejeté" ||
    children === "Irrecevable" ||
    children === "Non soutenu" ||
    children === "Irrecevable 40"
  ) {
    return {
      textcolor: "#B97B7B",
      backgroundColor: "#FFF2F2",
      fontWeight: "700",
    };
  } else if (children === "Discuté") {
    return {
      textcolor: "#828283",
      backgroundColor: "#F5F5F5",
      fontWeight: "700",
    };
  } else {
    return {
      textcolor: "#ffffff",
      backgroundColor: "#646464",
      fontWeight: "500",
    };
  }
};

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 18px;
  background: ${(props) => props.backgroundColor};
  width: fit-content;
`;

const Text = styled.span<{ textcolor: string; fontWeight: string }>`
  color: ${(props) => props.textcolor};
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight};
  line-height: 22px; /* 183.333% */
`;

const Category: React.FC<TextProps> = ({ children }) => {
  const { textcolor, backgroundColor, fontWeight } = getStyles(children);

  return (
    <Container backgroundColor={backgroundColor}>
      <Text textcolor={textcolor} fontWeight={fontWeight}>
        {children}
      </Text>
    </Container>
  );
};

export default Category;
