import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface CarrouselProps {
  children: React.ReactNode;
  className?: string;
  color?: string;
  title: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  align-items: left;
  width: 100%;
  padding: 0px;
  overflow: hidden;
  left: 0;
  right: 0;
  position: absolute;
`;

const Test = styled.div`
  position: relative;
  overflow: hidden;
  left: 0;
  right: 0;
`;

const Title = styled.h2`
  color: #202020;
  font-family: "Sharp Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
`;

const NavigationButton = styled.button<{
  direction: "left" | "right";
  disabled?: boolean;
}>`
  position: absolute;
  top: 50%;
  ${(props) => (props.direction === "left" ? "left: 0;" : "right: 0;")}
  transform: translateY(-50%);
  border: 0px solid #ebebeb;
  display: ${(props) => (props.disabled ? "none" : "flex")};
  background-color: ${(props) =>
    props.disabled ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.02)"};
  width: 40px;
  height: 100%;

  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;

  &:hover {
    color: #ff734f;
    background-color: rgba(0, 0, 0, 0.06);
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  gap: 12px;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 48px;
  min-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  scroll-snap-type: x mandatory;
  > * {
    scroll-snap-align: start;
    flex-shrink: 0;
    max-width: 300px;
  }
`;

const Carrousel: React.FC<CarrouselProps> = ({ children, title }) => {
  const [numberScroll, setNumberScroll] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: "left" | "right") => {
    if (wrapperRef.current) {
      const cardWidth = 300; // Width of each card
      const gap = 12; // Gap between cards
      const scrollAmount = (cardWidth + gap) * 2; // Scroll 3 cards at a time
      wrapperRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
    if (direction === "left") {
      setNumberScroll(numberScroll + 1);
    } else {
      setNumberScroll(numberScroll - 1);
    }
  };

  useEffect(() => {
    if (numberScroll === 0) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  }, [numberScroll]);

  return (
    <Container>
      <Title>{title}</Title>
      <Test>
        <NavigationButton
          direction="left"
          onClick={() => scroll("left")}
          disabled={!isScrolled}
        >
          <ChevronLeft size={24} color="#ff734f" />
        </NavigationButton>
        <Wrapper ref={wrapperRef}>{children}</Wrapper>
        <NavigationButton direction="right" onClick={() => scroll("right")}>
          <ChevronRight size={24} color="#ff734f" />
        </NavigationButton>
      </Test>
    </Container>
  );
};

export default Carrousel;
