import React from "react";
import styled from "styled-components";

interface ErrorMessageProps {
  children?: React.ReactNode;
}

const ErrorMessageHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorMessageText = styled.span`
  color: #e70000;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-weight: 500;
  margin-top: -8px;
  text-align: center;
`;
const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => {
  return (
    <ErrorMessageHero>
      <ErrorMessageText>{children}</ErrorMessageText>
    </ErrorMessageHero>
  );
};

export default ErrorMessage;
