import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Button from "../cta/Button";
import { DropdownProps } from "../../types/Dropdown";
import { Link } from "react-router-dom";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  //max-width: 100%; /* Ensure the container takes the full width of its parent */
  color: #828283;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  width: auto;
`;

const DropdownHeader = styled.div<{ isHovered: boolean }>`
  padding: 10px;
  background-color: ${({ isHovered }) => (isHovered ? "#FFF7F2" : "#fff")};
  border: none;
  text-align: left;
  border-radius: 18px;
  &:hover {
    background-color: var(--Main-color, #fff7f2);
    color: var(--Main-color, #ff734f);
  }
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis (...) to overflowed text */
  width: auto;
  max-width: 100%; /* Ensure it takes the full width of the container */
`;

const Dropdown = styled.ul`
  position: absolute; /* Allow the dropdown to overflow the parent */
  top: 100%; /* Position it below the header */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it appears above other elements */
  background: #fff;
  text-align: left;
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  border-radius: 18px; /* Apply border-radius to the dropdown */
  box-shadow: 0px 2px 40px 16px rgba(0, 0, 0, 0.05);
  /* Enable scrolling if content overflows */
  width: fit-content; /* Allow the width to be larger than the parent */
`;

const DropdownList = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
  min-width: 520px;
  max-height: 300px; /* Optional: limit the max height */
  overflow-y: auto;
`;

const DropdownListItem = styled.li`
  display: flex;
  padding: 10px 10px;
  align-items: center;
  gap: 10px;
  width: fit-content;
`;

const Footer = styled.div`
  display: flex;
  padding: 10px 10px 10px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const SearchInput = styled.input`
  display: flex;
  height: 54px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  padding: 17px 10px 17px 20px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
  color: #b7b7b7;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  &:focus {
    outline: none;
  }
`;

const MultiSelectDropDown: React.FC<DropdownProps> = ({
  items,
  onSelect,
  enableSearch = true,
  multiChoice = true,
  placeholder,
  selected = [], // Default to empty array
  isHovered = false, // Default to false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCache, setSelectedCache] =
    useState<{ label: string; value: string }[]>(selected);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (item: { label: string; value: string }) => {
    if (multiChoice) {
      const isSelected = selectedCache.some((i) => i.value === item.value);
      const newSelected = isSelected
        ? selectedCache.filter((i) => i.value !== item.value)
        : [...selectedCache, item];
      setSelectedCache(newSelected);
    } else {
      onSelect([item]);
      setIsOpen(false);
    }
  };

  const handleSubmit = () => {
    onSelect(selectedCache);
    setIsOpen(false);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);

      // Submit the filters if multiChoice is true when clicking outside
      setSelectedCache((prevSelectedCache) => {
        onSelect(prevSelectedCache); // Submit the latest selectedCache
        return prevSelectedCache;
      });
    }
  };

  useEffect(() => {
    // Sync selectedCache with selected prop ONLY for selectedVal
    if (selected !== selectedCache) {
      setSelectedCache(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]); //

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const filteredItems = items.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownHeader onClick={() => setIsOpen(!isOpen)} isHovered={isHovered}>
        {selected.length > 0
          ? selected.map((item) => item.label).join(", ")
          : placeholder}
      </DropdownHeader>
      {isOpen && (
        <Dropdown>
          {enableSearch && (
            <SearchInput
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          )}
          <DropdownList>
            {filteredItems.map((item) => (
              <DropdownListItem
                key={item.value}
                onClick={() => handleSelect(item)}
              >
                {item.label}
                {selectedCache.some((i) => i.value === item.value) && "✓"}
              </DropdownListItem>
            ))}
          </DropdownList>
          <Footer>
            {multiChoice && (
              <Button typeButton="primary" onClick={handleSubmit}>
                Appliquer
              </Button>
            )}
          </Footer>
        </Dropdown>
      )}
    </DropdownContainer>
  );
};

export default MultiSelectDropDown;
