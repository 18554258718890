import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Tooltip from "../cta/Tooltip";
// Define an interface for the props

interface Field {
  name: string;
  link: string;
  disabled?: boolean;
}

interface MenuFieldProps {
  iconSrc: string;
  text: string;
  link: string;
  hasText?: boolean;
  hasAmendments?: boolean;
  hasSpeeches?: boolean;
  fields?: Field[];
}
const MenuFieldHero = styled.div`
  display: flex;
  padding: 22px 192px;
  justify-content: center;
  align-items: center;
  gap: 50px;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fcfcfc;
`;

const Element = styled(Link)<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? "#CACACA" : "#232129")};
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const FileMenu: React.FC<MenuFieldProps> = ({
  text,
  iconSrc,
  link,
  hasText = true,
  hasAmendments = true,
  hasSpeeches = true,
  fields,
}) => {
  return (
    <MenuFieldHero>
      {fields &&
        fields.map((field) => (
          <Element key={field.link} to={field.link} disabled={field.disabled}>
            {field.name}
          </Element>
        ))}
    </MenuFieldHero>
  );
};

export default FileMenu;
