import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  FileNode,
  AmendmentNode,
  DeputyNode,
  SearchResult,
} from "../../types/Search";

// Define an interface for the props
interface SearchElementProps {
  result: SearchResult;
}
const SearchElementContainer = styled(Link)`
  display: flex;
  padding: 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 15px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fff;
  text-decoration: none;
  cursor: pointer;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
`;

const Result = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  text-align: left;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: #3c3c3d;
`;

const TextTitle = styled.span`
  display: block;
  color: #232129;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
`;

const TextDescription = styled.span`
  display: block;
  color: #6e6e6e;
  font-family: "Sharp Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
`;
const renderResult = (result: SearchResult) => {
  if (result.type === "file") {
    // FileNode or AmendmentNode
    return (
      <Result>
        <TextTitle>{(result as FileNode | AmendmentNode).name}</TextTitle>
        <TextDescription>{(result as FileNode).wording}</TextDescription>
      </Result>
    );
  } else if (result.type === "deputy") {
    // DeputyNode
    return (
      <Card>
        <Icon
          src={
            "https://res.cloudinary.com/dhpdocirj/image/upload/" +
            (result as DeputyNode).deputy_id
          }
        />
        <Result>
          <TextTitle>
            {(result as DeputyNode).first_name}{" "}
            {(result as DeputyNode).last_name}
          </TextTitle>
          <TextDescription>
            {"Député de "}
            {(result as DeputyNode).departement}
          </TextDescription>
        </Result>
      </Card>
    );
  }
};

const SearchElement: React.FC<SearchElementProps> = ({ result }) => {
  const Redirect = () => {
    if (result.type === "file") {
      return `/file/${(result as FileNode).uid}/overview`;
    } else if (result.type === "deputy") {
      return `/people/${(result as DeputyNode).deputy_id}/overview`;
    }
    return "#";
  };
  const path = Redirect();
  return (
    <SearchElementContainer to={path}>
      {renderResult(result)}
    </SearchElementContainer>
  );
};

export default SearchElement;
