import React, { useEffect } from "react";
import "./App.css";
import SideBar from "./components/nav/Sidebar";
import Home from "./pages/Home";
import About from "./pages/About";
import Files from "./pages/Files/Files";
import File from "./pages/Files/File/File";
import Amendts from "./pages/Files/File/Amendts";
import { Routes, Route, Navigate } from "react-router-dom";
import Overview from "./pages/Files/File/Overview";
import TextHandler from "./pages/Files/File/TextHandler";
import LoginPage from "./pages/LoginPage"; // Import the LoginPage
import useAuth, { AuthProvider } from "./hooks/useAuth"; // Adjust the path if necessary
import ProtectedRoute from "./components/nav/ProtectedRoute"; // Adjust the path if necessary
import AmendtsDetails from "./pages/Files/File/AmendtsDetails";
import SignUpPage from "./pages/SignUpPage";
import Alerts from "./pages/Alerts/Alerts";
import ConfigAlert from "./pages/Alerts/ConfigAlert";
import styled from "styled-components";
import Summary from "./pages/Alerts/Summary";
import Chartmaker from "./pages/Chartmaker";
import Speeches from "./pages/Files/File/Speeches";
import Account from "./pages/Account";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Loader from "./components/interaction/Loader";
import { Helmet, HelmetProvider } from "react-helmet-async";
import favicon from "./assets/img/logos/faviconTest.png";
import GlobalStyles from "./GlobalStyles";
import PeopleList from "./pages/People/PeopleList";
import Profil from "./pages/People/Profil/Profil";
import OverviewProfil from "./pages/People/Profil/OverviewProfil";
import SpeechesProfil from "./pages/People/Profil/Speeches";
import AmendtsProfil from "./pages/People/Profil/Amendts";

const Hero = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PageContainer = styled.div`
  display: flex;
  width: 100%;
`;
const RoutesComponent: React.FC = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Routes>
      {/* Public Route */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/chartmaker" element={<Chartmaker />} />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="/account" element={<Account />} />
        <Route path="/alerts/config" element={<ConfigAlert />} />
        <Route path="/alerts/config/:alertId" element={<ConfigAlert />} />
        <Route path="/alerts/:alertId" element={<Summary />} />
        <Route path="/people" element={<PeopleList />} />
        <Route path="/people/:deputyId" element={<Profil />}>
          <Route path="overview" element={<OverviewProfil />} />
          <Route path="speeches" element={<SpeechesProfil />} />
          <Route path="amendts" element={<AmendtsProfil />} />
          <Route path="votes" element={<Overview />} />
          <Route path="amendments/:amendtsId" element={<AmendtsDetails />} />
        </Route>
        <Route path="/files" element={<Files />} />
        <Route path="/file/:fileId" element={<File />}>
          <Route path="overview" element={<Overview />} />
          <Route path="text" element={<TextHandler />} />
          <Route path="amendts" element={<Amendts />} />
          <Route path="amendts/:amendtsId" element={<AmendtsDetails />} />
          <Route path="speeches" element={<Speeches />} />
        </Route>
      </Route>

      {/* Redirect any unknown routes to home or login */}
      <Route
        path="*"
        element={user ? <Navigate to="/" /> : <Navigate to="/login" />}
      />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );
};

const App: React.FC = () => {
  useEffect(() => {
    document.title = "Parabole"; // Set the title you want
  }, []);
  return (
    <>
      <GlobalStyles />
      <AuthProvider>
        <HelmetProvider>
          <Helmet>
            <title>Parabole - Analyse de documents</title>
            <link rel="icon" href={favicon} />
            <meta
              name="description"
              content="Parabole est un outil de veille legislative qui transforme la manière de suivre les débats parlementaires."
            />
            <meta
              name="keywords"
              content="veille, legislative, débats, parlementaires, documents, analyse, Parabole"
            />
            <meta name="author" content="Parabole" />

            <meta name="robots" content="index, follow" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>

          <Hero className="App">
            <SideBar />
            <PageContainer>
              <RoutesComponent />
            </PageContainer>
          </Hero>
        </HelmetProvider>
      </AuthProvider>
    </>
  );
};

export default App;
