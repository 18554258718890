import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface State {
  title: string;
  subtitle: string;
  shortSubTitle: string;
  currentStep: boolean;
  rank: number;
  textId: string;
  stageFileId: string;
  hasAmendments: boolean;
}

interface ProgressBarProps {
  steps: State[];
  onStepClick: (
    rank: number,
    title: string,
    subtitle: string,
    textId: string,
    stageFileId: string,
    hasAmendments: boolean
  ) => void;
}

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 600px;
  z-index: 50;
`;

const StepsContainer = styled.div`
  display: flex;
  gap: 50px;
  border-radius: 20px;
  align-items: center;
  width: 100%;
  height: 10px;
  position: relative;
`;

const ProgressLine = styled.div`
  position: absolute;
  height: 5px;
  /* width: calc(50% - 20px); Adjust for the break */
  width: 100%;
  background-color: #393939;
  border-radius: 20px;
  z-index: -1;
`;

const ProgressLineFilled = styled.div<{
  width: number;
  isBreakpointEnabled: boolean;
}>`
  position: absolute;
  height: 5px;
  width: ${({ width, isBreakpointEnabled }) =>
    isBreakpointEnabled ? `calc(${width}px - 90px)` : `${width}px`};
  background-color: #ff734f;
  z-index: -1;
  border-radius: 20px;
`;

const StepButtonContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepButton = styled.button<{
  isActive: boolean;
  currentStep: boolean;
  afterCurrentStep: boolean;
  hasTextId: boolean;
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  width: ${({ hasTextId }) => (hasTextId ? "20px" : "15px")};
  height: ${({ hasTextId }) => (hasTextId ? "20px" : "15px")};
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepDot = styled.div<{
  isActive: boolean;
  currentStep: boolean;
  afterCurrentStep: boolean;
  hasTextId: boolean;
}>`
  background-color: ${({ afterCurrentStep }) =>
    afterCurrentStep ? "#FF734F" : "#FF734F"};
  box-shadow: ${({ isActive }) => (isActive ? "0 0 0 2px #ffffff" : "none")};
  border-radius: 50%;
  width: ${({ hasTextId }) => (hasTextId ? "15px" : "10px")};
  height: ${({ hasTextId }) => (hasTextId ? "15px" : "10px")};
  transition: background-color 0.3s, width 0.3s, height 0.3s;
`;

const BreakPointContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const BreakPoint = styled.div<{ showTooltip: boolean }>`
  width: ${({ showTooltip }) => (showTooltip ? "60px" : "40px")};
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #232129;
  border-radius: 10px;
  z-index: 1;
  transition: all 0.3s ease;
`;

const BreakDots = styled.div`
  display: flex;
  justify-content: space-between;
  width: 20px;
  height: 5px;
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #ff734f;
  border-radius: 50%;
`;

const Tooltip = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const StepMeta = styled.div`
  text-align: center;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 16px;
  font-family: "Sharp Sans";
`;

const Title = styled.span`
  text-align: center;
  font-weight: 700;
  color: #ffffff;
`;

const Description = styled.span`
  text-align: center;
  color: #ffffff;
`;

const HiddenListContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 500px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 2px 40px 16px rgba(0, 0, 0, 0.05);
  z-index: 100;
  text-decoration: none;
  text-align: left;
`;

const HiddenListSpan = styled.span`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding: 20px;
  line-height: 22px;
  border-radius: 18px;
  &:hover {
    background-color: #f5f5f5;
  }
  cursor: pointer;
`;

const HiddenList: React.FC<{
  steps: State[];
  handleStepClick: (
    rank: number,
    title: string,
    subtitle: string,
    textId: string,
    stageFileId: string,
    hasAmendments: boolean
  ) => void;
}> = ({ steps, handleStepClick }) => {
  return (
    <HiddenListContainer>
      {steps.map((step, index) => (
        <HiddenListSpan
          onClick={() =>
            handleStepClick(
              step.rank,
              step.title,
              step.shortSubTitle,
              step.textId,
              step.stageFileId,
              step.hasAmendments
            )
          }
          key={index}
        >
          {step.title} - {step.subtitle}
        </HiddenListSpan>
      ))}
    </HiddenListContainer>
  );
};

const FileProgressBar: React.FC<ProgressBarProps> = ({
  steps,
  onStepClick,
}) => {
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [clickedStep, setClickedStep] = useState<number | null>(null);
  const [widthBar, setWidthBar] = useState(0);
  const [hiddenListVisible, setHiddenListVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const currentStep = steps.find((step) => step.currentStep);
    if (currentStep) {
      setActiveStep(currentStep.rank - 1); // Adjusting rank to zero-based index

      // Calculate the total width up to the center of the current step
      const totalWidth = steps.reduce((acc, step, index) => {
        if (index < currentStep.rank - 1) {
          // Add full gap for previous steps
          return acc + (step.textId ? 15 : 10) + 38;
        } else if (index === currentStep.rank - 1) {
          // Add half of the current step's width
          return acc + (step.textId ? 7.5 : 5);
        }
        return acc;
      }, 0);

      setWidthBar(totalWidth);
    }
  }, [steps]);

  const handleClick = (
    rank: number,
    title: string,
    subtitle: string,
    textId: string,
    stageFileId: string,
    hasAmendments: boolean
  ) => {
    onStepClick(rank, title, subtitle, textId, stageFileId, hasAmendments);
    setActiveStep(rank - 1);
    setClickedStep(rank - 1);
    console.log("clickedStep", clickedStep);
  };

  const isBreakpointEnabled = steps.length > 13;
  const visibleSteps = isBreakpointEnabled
    ? steps.slice(0, 6).concat(steps.slice(-6))
    : steps;
  const hiddenSteps = isBreakpointEnabled ? steps.slice(6, -6) : [];

  return (
    <ProgressBarContainer>
      <StepsContainer>
        <ProgressLine />
        <ProgressLineFilled
          width={widthBar}
          isBreakpointEnabled={isBreakpointEnabled}
        />
        {visibleSteps.map((step, index) => {
          if (isBreakpointEnabled && index === 6) {
            return (
              <BreakPointContainer
                key="breakpoint"
                onClick={() => setHiddenListVisible(!hiddenListVisible)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <BreakPoint showTooltip={showTooltip}>
                  <BreakDots>
                    <Dot />
                    <Dot />
                    <Dot />
                  </BreakDots>
                </BreakPoint>
                {hiddenListVisible && (
                  <HiddenList
                    steps={hiddenSteps}
                    handleStepClick={handleClick}
                  />
                )}
              </BreakPointContainer>
            );
          }
          return (
            <StepButtonContainer key={index}>
              <StepButton
                isActive={activeStep === index}
                currentStep={step.currentStep}
                afterCurrentStep={step.currentStep}
                hasTextId={!!step.textId}
                onClick={() =>
                  handleClick(
                    step.rank +
                      (isBreakpointEnabled
                        ? step.rank > 6
                          ? -hiddenSteps.length
                          : 0
                        : 0),
                    step.title,
                    step.shortSubTitle,
                    step.textId,
                    step.stageFileId,
                    step.hasAmendments
                  )
                }
              >
                <StepDot
                  isActive={activeStep === index}
                  currentStep={step.currentStep}
                  afterCurrentStep={step.currentStep}
                  hasTextId={!!step.textId}
                />
              </StepButton>
            </StepButtonContainer>
          );
        })}
      </StepsContainer>
      <StepMeta>
        {activeStep !== null && steps[activeStep] && (
          <>
            <Title>{steps[activeStep].title}</Title>
            <Description>{steps[activeStep].subtitle}</Description>
          </>
        )}
      </StepMeta>
    </ProgressBarContainer>
  );
};

export default FileProgressBar;
