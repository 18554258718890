import React, { useState, useEffect } from "react";
import styled from "styled-components";
import close from "../../assets/img/icons/close.png";
import MutliSelectDropDown from "../nav/MultiSelectDropDown";
import useMetrics from "../../hooks/filters/useMetrics";
import useValues from "../../hooks/filters/useValues";

interface FilterProps {
  id: number;
  onRemove: (id: number) => void;
  onFilterChange: (
    id: number,
    filters: {
      metric: { label: string; value: string }[];
      symbol: { label: string; value: string }[];
      value: { label: string; value: string }[];
    }
  ) => void;
  children?: React.ReactNode;
  className?: string;
  color?: string;
  margin?: string;
  grow?: number;
  textId?: string;
  filterId?: string;
  initialFilter?: {
    metric: { label: string; value: string }[];
    symbol: { label: string; value: string }[];
    value: { label: string; value: string }[];
  };
}

const symbols = [
  { label: "=", value: "=" },
  { label: "!=", value: "<>" },
  { label: ">", value: ">" },
  { label: "<", value: "<" },
  { label: ">=", value: ">=" },
  { label: "<=", value: "<=" },
];

const Container = styled.div`
  //width: 100%;
  min-width: 220px;
`;

const Down = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const Field = styled.div<{ isHovered: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  justify-content: space-between;
  max-width: 300px;
  min-height: 40px;
  box-sizing: border-box;
`;

const CloseButton = styled.div<{ isHovered: boolean }>`
  cursor: pointer;
  visibility: ${({ isHovered }) => (isHovered ? "visible" : "hidden")};
  width: 11.357px;
  height: 11.357px;
  flex-shrink: 0;
`;

const CloseArrow = styled.img`
  width: 11.357px;
  height: 11.357px;
`;

const Select = styled.div<{ firststepdone?: boolean }>`
  display: ${({ firststepdone }) => (firststepdone ? "flex" : "none")};
  padding: 10px 0px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const FilterText: React.FC<FilterProps> = ({
  id,
  onRemove,
  onFilterChange,
  textId,
  filterId,
  initialFilter,
}) => {
  const [selected, setSelected] = useState<{ label: string; value: string }[]>(
    initialFilter?.metric || []
  );
  const [selectedSymb, setSelectedSymb] = useState<
    { label: string; value: string }[]
  >(initialFilter?.symbol || [{ label: "=", value: "=" }]);
  const [selectedVal, setSelectedVal] = useState<
    { label: string; value: string }[]
  >(initialFilter?.value || []);
  const [firstStepDone, setFirstStepDone] = useState(
    !!initialFilter?.metric.length
  );
  const [isHovered, setIsHovered] = useState(false);
  const [values, setValues] = useState<{ label: string; value: string }[]>([]);
  const { data: metrics, loading, error } = useMetrics();

  const {
    data: datavalues,
    loading: loadingValues,
    error: errorValues,
  } = useValues(selected, textId, filterId);

  useEffect(() => {
    // Reset symbol and value when metric changes
    setSelectedSymb([{ label: "=", value: "=" }]);
    setSelectedVal([]);

    // Validate the first step to display the second step
    if (selected.length > 0) {
      setFirstStepDone(true);
      console.log("First step done with textId : ", textId);
    } else {
      setFirstStepDone(false);
    }
  }, [selected, textId]);

  useEffect(() => {
    setIsHovered(false);
  }, [selected, selectedSymb, selectedVal, textId]);

  useEffect(() => {
    if (selected.length > 0) {
      onFilterChange(id, {
        metric: selected,
        symbol: selectedSymb,
        value: selectedVal,
      });
    } else {
      // Call onFilterChange with empty arrays when deselecting
      onFilterChange(id, {
        metric: [],
        symbol: [],
        value: [],
      });
    }
  }, [selected, selectedSymb, selectedVal, id, onFilterChange, textId]);

  const metricItems = (metrics || []) as { label: string; value: string }[];
  const valuesItems = (datavalues || []) as { label: string; value: string }[];
  return (
    <Container
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Down>
        <Field isHovered={isHovered}>
          <MutliSelectDropDown
            items={metricItems}
            multiChoice={false}
            onSelect={setSelected}
            placeholder="Selectionnez un nouveau filtre..."
            selected={selected} // Pass selected state
            isHovered={isHovered} // Pass hover state
          />
          <CloseButton isHovered={isHovered} onClick={() => onRemove(id)}>
            <CloseArrow src={close} />
          </CloseButton>
        </Field>
      </Down>
      <Down>
        <Select firststepdone={firstStepDone}>
          <Field isHovered={isHovered}>
            <MutliSelectDropDown
              items={symbols}
              multiChoice={false}
              enableSearch={false}
              onSelect={setSelectedSymb}
              placeholder={symbols[0].label}
              selected={selectedSymb} // Pass selected state
              isHovered={isHovered} // Pass hover state
            />
          </Field>
          <Field isHovered={isHovered} style={{ width: "100%" }}>
            <MutliSelectDropDown
              items={valuesItems}
              onSelect={setSelectedVal}
              placeholder="Selectionnez une valeur"
              selected={selectedVal} // Pass selected state
              isHovered={isHovered} // Pass hover state
            />
          </Field>
        </Select>
      </Down>
    </Container>
  );
};

export default FilterText;
