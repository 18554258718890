import { useState } from "react";
import styled from "styled-components";
import useFile from "../../hooks/useFile";
import FileSum from "../../components/blocks/fileSum";
import PageContainer from "../../components/containers/PageContainer";
import SearchBar from "../../components/search/searchBar";
import Loader from "../../components/interaction/Loader";
import PageNavigator from "../../components/nav/PageNavigator";
import usePeople from "../../hooks/usePeople";
import Card from "../../components/people/Card";
import Carrousel from "../../components/containers/Carrousel";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  overflow: hidden;
  justify-content: left;
  align-items: left;
  position: relative;
`;

const SearchContainer = styled.div`
  width: 600px;
`;

const PeopleList = () => {
  const [pageSelected, setPageSelected] = useState(1);
  const { data: random, loading, error } = usePeople();
  const {
    data: adopted,
    loading: adoptedLoading,
    error: adoptedError,
  } = usePeople("adopted");
  console.log("Hook data:", random);

  if (loading) {
    return (
      <PageContainer>
        <Loader page={true} />
      </PageContainer>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!random || !random.People) {
    return (
      <PageContainer>
        <p>No data</p>
      </PageContainer>
    );
  }

  if (!adopted || !adopted.People) {
    return (
      <PageContainer>
        <p>No data</p>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <Container>
        <Carrousel title="Ils pourraient vous intéresser">
          {random.People.map((People) => (
            <Card
              key={People.deputyId}
              fullName={People.fullName}
              groupNameShort={People.groupNameShort}
              deputyId={People.deputyId}
              firstName={People.firstName}
              lastName={People.lastName}
              amendmentsCount={People.amendmentsCount}
              amendmentsAccepted={People.amendmentsAccepted}
            />
          ))}
        </Carrousel>
      </Container>
      <Container>
        <Carrousel title="Leurs amendements sont les mieux reçus">
          {adopted.People.map((People) => (
            <Card
              key={People.deputyId}
              fullName={People.fullName}
              groupNameShort={People.groupNameShort}
              deputyId={People.deputyId}
              firstName={People.firstName}
              lastName={People.lastName}
              amendmentsCount={People.amendmentsCount}
              amendmentsAccepted={People.amendmentsAccepted}
            />
          ))}
        </Carrousel>
      </Container>
    </PageContainer>
  );
};

export default PeopleList;
