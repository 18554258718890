import React from "react";
import styled from "styled-components";

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 100px;
  left: auto;
  z-index: 1000;
  background-color: #e70000;
  color: #ffffff;
  line-height: 1;
  font-size: 12px;
  font-family: "Sharp Sans";
`;

interface ErrorOverlayProps {
  error: string;
  onClose: () => void;
}

const ErrorOverlay: React.FC<ErrorOverlayProps> = ({ error, onClose }) => {
  return (
    <ErrorMessage onClick={(e) => e.stopPropagation()}>
      <p>{error}</p>
      <button onClick={onClose}>x</button>
    </ErrorMessage>
  );
};

export default ErrorOverlay;
