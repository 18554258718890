import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth"; // Adjust the import path as necessary

const ProtectedRoute: React.FC = () => {
  const { tokenUser, user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>; // Show a spinner or loading indicator
  }

  // Check if the user is authenticated
  if (user && tokenUser) {
    return <Outlet />;
  }

  // If not authenticated, redirect to the login page
  return <Navigate to="/login" />;
};

export default ProtectedRoute;
