import { useState, useCallback } from "react";
import axios from "axios";
import { MAIN_URL } from "../config";

interface UseTextSavedResponse {
  savedTexts: string[];
  saveText: (text: string) => Promise<void>;
  error: string | null;
  deleteHighlight: (highlightId: string) => Promise<void>;
}

const useTextSaved = (
  paragraphId: string,
  userId: number
): UseTextSavedResponse => {
  const [savedTexts, setSavedTexts] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const saveText = async (text: string) => {
    console.log("text:", text);
    console.log("userId:", userId);
    console.log("paragraphId:", paragraphId);
    try {
      const response = await axios({
        method: "POST",
        url: `${MAIN_URL}textprovider/textSaved`,
        data: {
          user_id: userId,
          userSavedKeyWord: text,
          paragraphId: paragraphId,
        },
      });

      if (response.status === 200) {
        setSavedTexts((prevTexts) => [...prevTexts, text]);
      }
    } catch (err) {
      setError("An error occurred while saving the text");
      console.error("Text saver error:", err);
    }
  };

  const deleteHighlight = useCallback(async (highlightId: string) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${MAIN_URL}textprovider/textSaved`,
        data: {
          user_id: userId,
          userSavedKeyWord: highlightId,
          paragraphId: paragraphId,
        },
      });
    } catch (error) {
      console.error("Error deleting highlight:", error);
      // Handle the error appropriately (e.g., show an error message to the user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { savedTexts, saveText, error, deleteHighlight };
};

export default useTextSaved;
