import { useState, useEffect } from "react";
import { Filter } from "../../types/Filters";

export const usePersistedFilters = (id: string) => {
  const [filters, setFilters] = useState<Filter[]>(() => {
    try {
      const stored = localStorage.getItem(`filters_${id}`);
      if (stored) {
        const parsedFilters = JSON.parse(stored);
        // Ensure all required properties exist
        return parsedFilters.map((filter: Filter) => ({
          id: filter.id,
          metric: filter.metric || [],
          symbol: filter.symbol || [],
          value: filter.value || [],
        }));
      }
    } catch (error) {
      console.error("Error parsing stored filters:", error);
    }
    return [
      {
        id: 0,
        metric: [],
        symbol: [],
        value: [],
      },
    ];
  });

  useEffect(() => {
    if (id) {
      localStorage.setItem(`filters_${id}`, JSON.stringify(filters));
    }
  }, [filters, id]);

  return [filters, setFilters] as const;
};
