import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/cta/Button";
import FileProgressBar from "../../../components/cta/FileProgressBar";
import FileMenu from "../../../components/nav/FileMenu";
import useFollow from "../../../hooks/useFollow";
import useAuth from "../../../hooks/useAuth";
import PageContainer from "../../../components/containers/PageContainer";
import useProfil from "../../../hooks/useProfil";
import ProfilPic from "../../../components/img/profilPic";

const Content = styled.div`
  display: flex;
  padding: 24px 48px;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  border-radius: 20px;
  background: #232129;
  padding-bottom: 50px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const NameAndGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 10px;
`;

const TitleOverview = styled.h2`
  color: #fff;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 0;
  margin: 0;
`;

const GroupName = styled.h3`
  color: #fff;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
`;

const Profil = () => {
  const { deputyId } = useParams<{ deputyId: string }>();
  const { data, loading, error } = useProfil(deputyId);

  const fields = [
    { name: "Overview", link: "overview" },
    { name: "Interventions", link: "speeches" },
    { name: "Amendements", link: "amendts" },
    { name: "Votes", link: "votes", disabled: true },
  ];

  const { user } = useAuth();
  const {
    isFollowing,
    toggleFollow,
    error: followError,
  } = useFollow(deputyId || "", user?.user_id, "Deputy");
  // Move the state updates into a useEffect
  console.log("firstname", data?.firstName);
  console.log("isFollowing", isFollowing);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <PageContainer>
      <Content>
        <Button
          typeButton="secondary"
          typee={"test"}
          stretch={false}
          onClick={toggleFollow}
        >
          {isFollowing ? "Ne plus suivre" : "Suivre"}
        </Button>

        {data && (
          <Description>
            <ProfilPic deputyId={deputyId} size={150} profilPage={true} />
            <NameAndGroup>
              <TitleOverview>{data.fullName}</TitleOverview>
              <GroupName>{data.groupNameShort}</GroupName>
            </NameAndGroup>
          </Description>
        )}
      </Content>
      <FileMenu
        text={"Overview"}
        iconSrc={"https://via.placeholder.com/18"}
        link={"/overview"}
        hasSpeeches={false}
        fields={fields}
      />
      <Outlet
        context={{
          deputyId: deputyId,
          data: data,
        }}
      />
    </PageContainer>
  );
};

export default Profil;
