import { useState, useEffect } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";

interface Speeches {
  speechId: string;
  text: string;
  deputyId: string;
  fullName: string;
  date: string;
  typeMeeting: string;
}

interface SpeechesData {
  Speeches: Speeches[];
  total_pages: number;
}

interface UseSpeechDataResponse {
  data: SpeechesData | null;
  loading: boolean;
  error: string | null;
}

const useSpeech = (
  fileId?: string,
  deputyId?: string
): UseSpeechDataResponse => {
  const [data, setData] = useState<SpeechesData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${MAIN_URL}/textprovider/speeches`, {
          //const response = await axios.get(`http://localhost:1080/speeches`, {
          params: {
            deputyId: deputyId,
          },
        });
        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("Speech", data);

  return { data, loading, error };
};

export default useSpeech;
