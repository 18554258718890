import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";
import { MAIN_URL } from "../config";
import { GetJWT, CreateJWT } from "./useJWT";

interface AuthContextProps {
  register: (userName: string, email: string, password: string) => void;
  signin: (email: string, password: string) => Promise<boolean>;
  signout: () => void;
  tokenUser: string | null;
  userInfo: Record<string, any>;
  isLoading: boolean;
  user: any;
  user_id: number;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (token: string, password: string) => Promise<boolean>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Start with loading as true
  const [tokenUser, setTokenUser] = useState<string | null>(null);

  useEffect(() => {
    const initializeAuth = async () => {
      setIsLoading(true);
      try {
        const storedUser = localStorage.getItem("user");
        const storedToken = localStorage.getItem("token");

        if (storedUser && storedToken) {
          setUser(JSON.parse(storedUser));
          setTokenUser(storedToken);
        }
      } catch (error) {
        console.error("Failed to initialize authentication", error);
      } finally {
        setIsLoading(false); // Loading complete
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  useEffect(() => {
    if (tokenUser) {
      localStorage.setItem("token", tokenUser);
    } else {
      localStorage.removeItem("token");
    }
  }, [tokenUser]);

  const handleAuthentication = async (
    endpoint: string,
    data: Record<string, any>
  ) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${MAIN_URL}clientsystem/${endpoint}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const userInfo = response.data;
      setUser(userInfo);
      await CreateJWT(userInfo.user_id, setTokenUser);
      return true;
    } catch (error) {
      console.error(`${endpoint} error`, error);
      return false;
    } finally {
      //setIsLoading(false);
      return true;
    }
  };

  const register = (
    userName: string,
    email: string,
    password: string
  ): Promise<boolean> => {
    return handleAuthentication("register", {
      username: userName,
      email,
      password,
    });
  };

  const signin = async (email: string, password: string): Promise<boolean> => {
    try {
      const response = await axios.post(
        `${MAIN_URL}clientsystem/signin`,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const userInfo = response.data;
      setUser(userInfo);
      await CreateJWT(userInfo.user_id, setTokenUser);
      return true;
    } catch (error) {
      console.error("Signin error", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const signout = () => {
    setUser(null);
    setTokenUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };

  const forgotPassword = async (email: string): Promise<boolean> => {
    try {
      await axios.post(
        `${MAIN_URL}clientsystem/forgot-password`,
        { email },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return true;
    } catch (error) {
      console.error("Forgot password error", error);
      return false;
    } finally {
    }
  };

  const resetPassword = async (
    token: string,
    password: string
  ): Promise<boolean> => {
    setIsLoading(true);
    try {
      await axios.post(
        `${MAIN_URL}clientsystem/reset-password`,
        { token, password },
        { headers: { "Content-Type": "application/json" } }
      );
      return true;
    } catch (error) {
      console.error("Reset password error", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        register,
        signin,
        signout,
        tokenUser,
        userInfo: user || {},
        isLoading,
        user,
        user_id: user?.user_id,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
