import React, { useState } from "react";
import styled from "styled-components";
import { UserCircle2 } from "lucide-react";

const ContactCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 200px;
`;

const Profil = styled.div`
  width: 24px;
  height: 24px;
  background-color: #3c3c3d;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfilPic = styled.img`
  width: 23.374px;
  height: 23.374px;
  object-fit: cover;
`;

const Title = styled.span`
  color: var(--Main-color, #ff734f);
  font-family: "Sharp Sans";
  font-size: 12px;
  font-weight: 700;
`;

interface ContactCellProps {
  deputy: string;
  name: string;
}

const ContactCell: React.FC<ContactCellProps> = ({ deputy, name }) => {
  const [imgError, setImgError] = useState(false);

  return (
    <ContactCellContainer>
      <Profil>
        {imgError ? (
          <UserCircle2 size={24} strokeWidth={1} color="#eeeeee" />
        ) : (
          <ProfilPic
            src={`https://res.cloudinary.com/dhpdocirj/image/upload/${deputy}`}
            onError={() => setImgError(true)}
          />
        )}
      </Profil>
      <Title>{name}</Title>
    </ContactCellContainer>
  );
};

export default ContactCell;
