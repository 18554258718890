import { useState, useCallback, useEffect, useMemo } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import Table from "../../components/table/Table";
import useAmendments from "../../hooks/useAmendments";
import FilterText from "../../components/filters/FilterText";
import ContactCell from "../../components/table/ContactCell";
import formatDateToFrench from "../../utils/formatDateToFrench";
import Category from "../../components/text/Category";
import Loader from "../../components/interaction/Loader";
import PageNavigator from "../../components/nav/PageNavigator";
import Modules from "../../components/containers/Modules";
import { Filter } from "../../types/Filters";
import { usePersistedFilters } from "../../hooks/filters/usePersistedFilters";

interface AmdtsExplorerProps {
  textId?: string;
  fileId?: string;
  deputyId?: string;
}

const Module = styled.div<{ flexGrow?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: ${({ flexGrow }) => flexGrow || 1} 0 0;
`;

const Filters = styled.div`
  border-radius: 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const Header = styled.div`
  display: flex;
  height: 64px;
  padding: 0px 28px;
  align-items: center;
  align-self: stretch;
  border-radius: 20px 20px 0px 0px;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fcfcfc;
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const LinkAction = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  color: #ff734f;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
`;

const Params = styled.div`
  display: flex;
  padding: 0px 28px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const FilterContainer = styled.div`
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const Choice = styled.div`
  display: flex;
  padding: 20px 28px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 0px 0px 20px 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const ChoiceText = styled.button`
  color: #828283;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 100% */
  border: none;
  background: none;
  cursor: pointer;
`;

const AmdtsExplorer = ({ textId, fileId, deputyId }: AmdtsExplorerProps) => {
  const filterKey = deputyId ? deputyId : textId;
  const [filters, setFilters] = usePersistedFilters(filterKey || "");
  const [pageSelected, setPageSelected] = useState(1);
  const [pageList, setPageList] = useState<number[]>([]);
  const { data, loading, error } = useAmendments(
    fileId,
    textId,
    pageSelected,
    filters,
    deputyId
  );

  useEffect(() => {
    console.log("pageSelected", pageSelected);
  }, [pageSelected]);

  const handleFilterChange = useCallback(
    (id: number, newFilters: Partial<Filter>) => {
      setFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.id === id ? { ...filter, ...newFilters } : filter
        )
      );
    },
    [setFilters]
  );

  const addFilter = useCallback(() => {
    setFilters((prevFilters) => [
      ...prevFilters,
      {
        id: prevFilters.length,
        metric: [],
        symbol: [],
        value: [],
      },
    ]);
  }, [setFilters]);

  const removeFilter = useCallback(
    (id: number) => {
      setFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.id !== id)
      );
    },
    [setFilters]
  );

  useEffect(() => {
    if (textId && filters.length === 0) {
      setFilters([
        {
          id: 0,
          metric: [],
          symbol: [],
          value: [],
        },
      ]);
    }
  }, [textId, filters.length, setFilters]);

  const sanitizedFilters = useMemo(() => filters, [filters]);

  useEffect(() => {
    if (data) {
      setPageList(Array.from({ length: data.total_pages }, (_, i) => i + 1));
    }
  }, [data]);

  const renderFilters = useMemo(
    () =>
      filters.map((filter) => (
        <FilterContainer key={filter.id}>
          <FilterText
            id={filter.id}
            onRemove={() => removeFilter(filter.id)}
            onFilterChange={handleFilterChange}
            textId={textId || ""}
            filterId={deputyId || ""}
            initialFilter={filter}
          />
        </FilterContainer>
      )),
    [filters, handleFilterChange, removeFilter, textId, deputyId]
  );

  /*
  if (loading) {
    return <Loader />;
  }
 
  if (error) {
    return <p>Error: {error}</p>;
  }*/

  /*
  if (!data || !data.Amendments || data.Amendments.length === 0) {
    return <p>No amendments available.</p>;
  }*/

  const columns = [
    "N",
    deputyId ? "Texte" : "Parlementaire",
    "Date",
    "Emplacement",
    "Sort",
    "Statut",
  ];

  const baseRenderers = {
    N: (value: any, row: any) => <Title>{row.IdOrder}</Title>,
    Date: (value: any, row: any) => formatDateToFrench(row.CreatedAt),
    Emplacement: (value: any, row: any) => <Title>{row.Spot}</Title>,
    Sort: (value: any, row: any) => <Category>{row.Sort}</Category>,
    Statut: (value: any, row: any) => <Category>{row.Status}</Category>,
  };

  const customRenderers = {
    ...baseRenderers,
    ...(deputyId
      ? {
          Texte: (value: any, row: any) => (
            <LinkAction to={`/file/${row.FileId}/overview`}>
              {row.FileName}
            </LinkAction>
          ),
        }
      : {
          Parlementaire: (value: any, row: any) => (
            <ContactCell
              deputy={row.DeputyId}
              name={`${row.FirstName} ${row.LastName}`}
            />
          ),
        }),
  };

  return (
    <>
      <Modules>
        <Module flexGrow={1}>
          <Filters>
            <Header>
              <Title>Filtres</Title>
            </Header>
            <Params>{renderFilters}</Params>
            <Choice>
              <ChoiceText onClick={addFilter}>+ Filtre</ChoiceText>
              <ChoiceText>+ Groupe</ChoiceText>
            </Choice>
          </Filters>
        </Module>
        <Module flexGrow={6}>
          {data && (
            <Table
              columns={columns}
              data={data.Amendments}
              actionLink={`AmendmentId`}
              customRenderers={customRenderers}
              isLoading={loading}
              emptyDateMessage="Aucun amendement trouvé pour cette étape"
            />
          )}
        </Module>
      </Modules>
      <PageNavigator
        pageSelected={pageSelected}
        pageList={pageList}
        setPageSelected={setPageSelected}
      />
    </>
  );
};

export default AmdtsExplorer;
