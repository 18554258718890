import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Search } from "lucide-react";
import useSearch from "../../hooks/useSearch";
import {
  FileNode,
  AmendmentNode,
  DeputyNode,
  SearchResult,
} from "../../types/Search";
import SearchElement from "./searchElement";
import { TailSpin } from "react-loader-spinner";
import Tooltip from "../cta/Tooltip";

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  position: relative;
`;

const InputContainer = styled.div`
  flex: 1;
  width: 100%;
`;
const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  color: #828283;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;
  height: 100%;
  width: 100%;
`;

const SearchButton = styled.button`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: #ff734f;
  width: 50px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  display: flex;
  padding: 9px 7px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background: #fff;
  z-index: 1; /* Ensure the overlay is above other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 2px 40px 16px rgba(0, 0, 0, 0.05);
`;

const renderResult = (result: SearchResult) => {
  if (result.type === "file" || result.type === "amendment") {
    // FileNode or AmendmentNode
    return (
      <div>
        <strong>{(result as FileNode | AmendmentNode).name}</strong>:{" "}
        {(result as FileNode | AmendmentNode).content}
      </div>
    );
  } else if (result.type === "deputy") {
    // DeputyNode
    return (
      <div>
        <strong>
          {(result as DeputyNode).first_name} {(result as DeputyNode).last_name}
        </strong>
      </div>
    );
  }
};

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const { data, loading, error } = useSearch(query);

  return (
    <>
      <SearchBarContainer>
        <InputContainer>
          <Input
            type="text"
            placeholder="Demandez à Parabole de trouver ce que vous cherchez..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </InputContainer>

        <SearchButton>
          {loading ? (
            <TailSpin
              visible={true}
              height="20"
              width="20"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
          ) : (
            <Tooltip>
              <Search size={17} color="#fff" />
            </Tooltip>
          )}
        </SearchButton>
        {/*{loading && <p>Loading...</p>}*/}
        {/*{error && <p>{error}</p>}*/}
        {query && data && (
          <Overlay>
            {loading || error ? (
              <TailSpin visible={true} height="20" width="20" color="#FF734F" />
            ) : (
              data?.map((result) => <SearchElement result={result} />)
            )}
          </Overlay>
        )}
      </SearchBarContainer>
    </>
  );
};

export default SearchBar;
