import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import close from "../../assets/img/icons/close.png";
import MutliSelectDropDown from "../nav/MultiSelectDropDown";
import useValues from "../../hooks/filters/useValues";

interface FilterProps {
  id: number;
  onRemove: (id: number) => void;
  onFilterChange: (
    id: number,
    filters: {
      metric: { label: string; value: string }[];
      symbol: { label: string; value: string }[];
      value: { label: string; value: string }[];
    }
  ) => void;
  children?: React.ReactNode;
  className?: string;
  color?: string;
  margin?: string;
  grow?: number;
}

const symbols = [
  { label: "=", value: "=" },
  { label: "!=", value: "<>" },
  { label: ">", value: ">" },
  { label: "<", value: "<" },
  { label: ">=", value: ">=" },
  { label: "<=", value: "<=" },
];

const metrics = [
  //{ label: "Entité", value: "entity" },
  { label: "Député", value: "deputy" },
  { label: "Groupe", value: "group" },
  //{ label: "Assemblée", value: "assembly" },
];

const Container = styled.div`
  width: 100%;
`;

const Down = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const Field = styled.div<{ isHovered: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.div<{ isHovered: boolean }>`
  cursor: pointer;
  display: ${({ isHovered }) => (isHovered ? "block" : "none")};
`;

const CloseArrow = styled.img`
  width: 11.357px;
  height: 11.357px;
`;

const Select = styled.div<{ firststepdone?: boolean }>`
  display: ${({ firststepdone }) => (firststepdone ? "flex" : "none")};
  padding: 10px 0px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const FilterAlert: React.FC<FilterProps> = ({
  id,
  onRemove,
  onFilterChange,
}) => {
  const [selected, setSelected] = useState<{ label: string; value: string }[]>(
    []
  );
  const [selectedSymb, setSelectedSymb] = useState<
    { label: string; value: string }[]
  >([{ label: "=", value: "=" }]);
  const [selectedVal, setSelectedVal] = useState<
    { label: string; value: string }[]
  >([]);
  const [firstStepDone, setFirstStepDone] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [values, setValues] = useState<{ label: string; value: string }[]>([]);

  const {
    data: datavalues,
    loading: loadingValues,
    error: errorValues,
  } = useValues(selected, "");

  useEffect(() => {
    // Validate the first step to display the second step
    if (selected.length > 0) {
      setFirstStepDone(true);
    }
    //setSelectedVal([]);
  }, [selected]);

  useEffect(() => {
    setIsHovered(false);
  }, [selected, selectedSymb, selectedVal]);

  useEffect(() => {
    if (
      selected.length > 0 &&
      selectedSymb.length > 0 &&
      selectedVal.length > 0
    ) {
      onFilterChange(id, {
        metric: selected,
        symbol: selectedSymb,
        value: selectedVal,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVal, selectedSymb]);

  useEffect(() => {
    setSelectedVal([]);
    onFilterChange(id, {
      metric: selected,
      symbol: [],
      value: [],
    });
  }, [selected, id, onFilterChange]);

  const metricItems = (metrics || []) as { label: string; value: string }[];
  const valuesItems = (datavalues || []) as { label: string; value: string }[];

  return (
    <Container
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Down>
        <Field isHovered={isHovered}>
          <MutliSelectDropDown
            items={metricItems}
            multiChoice={false}
            onSelect={setSelected}
            placeholder="Selectionnez un nouveau filtre..."
            selected={selected} // Pass selected state
            isHovered={isHovered} // Pass hover state
          />
          <CloseButton isHovered={isHovered} onClick={() => onRemove(id)}>
            <CloseArrow src={close} />
          </CloseButton>
        </Field>
      </Down>
      <Down>
        <Select firststepdone={firstStepDone}>
          <Field isHovered={isHovered}>
            <MutliSelectDropDown
              items={symbols}
              multiChoice={false}
              enableSearch={false}
              onSelect={setSelectedSymb}
              placeholder={symbols[0].label}
              selected={selectedSymb} // Pass selected state
              isHovered={isHovered} // Pass hover state
            />
          </Field>
          <Field isHovered={isHovered} style={{ width: "100%" }}>
            <MutliSelectDropDown
              items={valuesItems}
              onSelect={setSelectedVal}
              placeholder="Selectionnez une valeur"
              selected={selectedVal} // Pass selected state
              isHovered={isHovered} // Pass hover state
            />
          </Field>
        </Select>
      </Down>
    </Container>
  );
};

export default FilterAlert;
