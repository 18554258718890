import React, { useState } from "react";
import styled from "styled-components";
import File from "../../assets/img/icons/file.svg";
import { Link } from "react-router-dom";
import Category from "../text/Category";
import { Check } from "lucide-react";
import DropDown from "../nav/DropDown";
import detailsIcon from "../../assets/img/icons/detailsIcon.png";
type FileSumProps = {
  uid?: string;
  name?: string;
  wording?: string;
  status?: string;
  stageFile?: string;
  stateFile?: string;
};

const FileSumContainer = styled.div`
  display: flex;
  width: 674px;
  padding: 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 15px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fff;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
`;

const Tags = styled.div`
  display: flex;
  padding: 10px 0px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const Illustration = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 35px;
  height: 35px;
  position: relative;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
`;

const IconStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  background-color: #ff6107;
  position: absolute;
  border-radius: 20px;
  top: 0;
  right: 0;
`;

const Title = styled(Link)`
  color: #232129;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  text-decoration: none;
`;

const Description = styled.span`
  color: #232129;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Options = styled.button`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  padding: 0;
  background: none;
`;

const FileSum: React.FC<FileSumProps> = ({
  uid,
  name,
  wording,
  status,
  stageFile,
  stateFile,
}) => {
  const items = [{ label: "Suivre", value: "follow", available: false }];
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  return (
    <FileSumContainer>
      <Top>
        <Illustration>
          <Icon src={File} />
          <IconStatus>
            <Check size={7} color="#fff" />
          </IconStatus>
        </Illustration>
        <Options>
          <DropDown
            src={detailsIcon}
            items={items}
            dropdownIsOpen={dropdownIsOpen}
            setDropdownIsOpen={setDropdownIsOpen}
          />
        </Options>
      </Top>
      <Titles>
        <Title key={uid} to={`/file/${uid}/overview`}>
          {name}
        </Title>
        <Description>{wording}</Description>
      </Titles>
      <Tags>
        <Category>{stateFile}</Category>
        <Category>{stageFile}</Category>
      </Tags>
    </FileSumContainer>
  );
};

export default FileSum;
