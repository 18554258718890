import { useState, useEffect } from "react";
import axios from "axios";
import { SearchResult } from "../types/Search";

// Define interfaces for your search results

interface UseSearchResponse {
  data: SearchResult[] | null;
  loading: boolean;
  error: string | null;
}

// Define the custom hook for search
const useSearch = (query: string): UseSearchResponse => {
  const [data, setData] = useState<SearchResult[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Clear data if query is empty
    if (!query) {
      setData(null);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        // Send the search request to your backend (adjust the URL as needed)
        const response = await axios.get(
          `https://api.oparl-app.com/search/query`,
          //`http://localhost:2000/query`,
          {
            params: {
              q: query,
            },
          }
        );

        // Assuming the API returns a list of search results in the expected format
        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [query]);

  return { data, loading, error };
};

export default useSearch;
