import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Title from "../components/text/Title";
import logo from "../assets/img/logos/logoXL.png";
import Button from "../components/cta/Button";
import LinkText from "../components/cta/LinkText";
import useAuth from "../hooks/useAuth";
import ErrorMessage from "../components/errors/ErrorMessage";
import { Loader } from "lucide-react";

const Container = styled.div`
  display: inline-flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #fcfcfc;
`;

const SignUpHero = styled.div`
  display: flex;
  width: 494px;
  padding: 42px 0px;
  flex-direction: column;
  align-items: center;
  gap: 71px;
  flex-shrink: 0;
  border-radius: 18px;
  background: #fff;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  align-self: stretch;
`;

const Logo = styled.img`
  width: 68.595px;
  height: 69.452px;
`;

const Form = styled.form`
  display: flex;
  width: 353px;
  flex-direction: column;
  align-items: center;
  gap: 17px;
`;

const Inputs = styled.div`
  display: flex;
  width: 353px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const Input = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const InputTitle = styled.span`
  color: #191919;
  align-self: stretch;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.14px;
  text-align: left;
`;

const InputText = styled.input`
  border: none;
  display: flex;
  height: 20px;
  padding: 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--Second-color, #fff7f2);
  &:focus {
    outline: none !important;
    border: none;
  }
`;

const About = styled.div`
  display: flex;
  width: 353px;
  height: 48px;
  justify-content: center;
  align-items: center;
`;

const SignUpPage: React.FC = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const { register, signin, isLoading, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!passwordsMatch) {
      alert("Les mots de passe ne correspondent pas. Veuillez réessayer.");
      return;
    }
    try {
      await register(username, email, password);

      const loginSuccess = await signin(email, password);

      if (loginSuccess) {
        navigate("/");
      } else {
        alert(
          "Inscription réussie, mais la connexion automatique a échoué. Veuillez vous connecter manuellement."
        );
        navigate("/login");
      }
    } catch (error) {
      console.error("Erreur d'inscription:", error);
      alert(
        "Une erreur s'est produite lors de l'inscription. Veuillez réessayer."
      );
    }
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <SignUpHero>
        <Top>
          <Logo src={logo} alt="logo" />
          <Title type="h1">Inscription</Title>
        </Top>
        <Form onSubmit={handleSubmit}>
          <Inputs>
            <Input>
              <InputTitle>Nom d'utilisateur</InputTitle>
              <InputText
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Input>
            <Input>
              <InputTitle>Adresse email</InputTitle>
              <InputText
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Input>
            <Input>
              <InputTitle>Mot de passe</InputTitle>
              <InputText
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Input>
            <Input>
              <InputTitle>Confirmer le mot de passe</InputTitle>
              <InputText
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Input>
          </Inputs>
          {!passwordsMatch && (
            <ErrorMessage>Les mots de passe ne correspondent pas</ErrorMessage>
          )}
          <Button
            typeButton="primary"
            type="submit"
            disabled={isLoading || !passwordsMatch}
            stretch={true}
          >
            {isLoading ? "..." : "S'inscrire"}
          </Button>
          <LinkText to="/login" type={"primary"}>
            Déjà un compte ? Se connecter
          </LinkText>
        </Form>
      </SignUpHero>
    </Container>
  );
};

export default SignUpPage;
