import PageContainer from "../components/containers/PageContainer";
import styled from "styled-components";
import Module from "../components/containers/Module";
import chartmaker from "../assets/img/illu/chartImg.png";
import SearchBar from "../components/search/searchBar";

const ChartHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  width: 100%;
  gap: 30px;
`;

const ChartTitle = styled.h1`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  margin: 0;
`;

const ChartDescription = styled.p`
  align-self: stretch;
  color: var(--MainTextColor, #6e6e6e);
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
  margin: 0;
`;

const ChartImg = styled.img`
  width: 150px;
  padding: 150px;
`;

const SearchContainer = styled.div`
  width: 600px;
`;

const Chartmaker = () => {
  return (
    <PageContainer>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <ChartHero>
        <ChartTitle>Visualisation</ChartTitle>
        <ChartDescription>
          Cet outil n’est pas disponible dans cette version de la bêta de
          Parabole.
        </ChartDescription>
        <Module alignItems="center">
          <ChartImg src={chartmaker} alt="chartmaker" />
        </Module>
      </ChartHero>
    </PageContainer>
  );
};

export default Chartmaker;
