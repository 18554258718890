import React, { useState, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components";
import Interaction from "../../components/filters/Interaction";
import FilterAlert from "../../components/filters/FilterAlert";
import Toggle from "../../components/cta/Toggle";
import Button from "../../components/cta/Button";
import useAlert from "../../hooks/useAlert";
import ErrorOverlay from "../../components/errors/ErrorOverlay";
import BackButton from "../../components/nav/BackButton";
import {
  useParams,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import PageContainer from "../../components/containers/PageContainer";
import SearchBar from "../../components/search/searchBar";
import Tooltip from "../../components/cta/Tooltip";
const ConfigAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  border-radius: 18px;
`;

const Header = styled.div`
  display: flex;
  height: 64px;
  padding: 0px 30px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 18px 18px 0px 0px;
  background: #fcfcfc;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
  align-items: center;
`;

const TitleHeader = styled.div`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const InputHeader = styled.input<{ isEditing: boolean }>`
  color: ${({ isEditing }) => (isEditing ? "#3c3c3d" : "#828283")};
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  border: none;
  padding: 0;

  &:focus-visible {
    outline: none;
  }
`;

const Details = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  gap: 8px;
  padding: 20px 30px;
`;

const SubText = styled.div`
  color: #828283;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;
  border-top: 1px solid var(--Main-stroke-1, #ebebeb);
  padding: 20px 30px;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FilterContent = styled.div`
  display: flex;
  padding-top: 10px;
  align-items: center;
  padding-left: 20px;
  height: fit-content;
  // min-height: 80px;
  border-left: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const Description = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  max-width: 500px;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
`;

const ToggleLabel = styled.span`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const ChoiceText = styled.button`
  color: #828283;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 100% */
  border: none;
  background: none;
  cursor: pointer;
`;

const KeywordInput = styled.input`
  display: block;
  border: none;
  border-bottom: 1px solid #ff734f;
  font-family: "Sharp Sans";
  font-weight: 500;
  color: #3c3c3d;
  outline: none;
  font-size: 12px;
  width: 150px;
  height: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 0px 20px 20px 30px;
  justify-content: center;
  margin-top: 20px;
`;

const SearchContainer = styled.div`
  width: 600px;
`;

interface Filter {
  id: number;
  metric: { label: string; value: string }[];
  symbol: { label: string; value: string }[];
  value: { label: string; value: string }[];
}

const ConfigAlert: React.FC = () => {
  const { alertId } = useParams<{ alertId: string }>();
  const location = useLocation();
  const existingAlert = location.state?.alert;

  const [isLive, setIsLive] = useState(existingAlert?.isLive || false);
  const [filters, setFilters] = useState<Filter[]>(
    existingAlert?.filters || []
  );
  const [keyword, setKeyword] = useState<string>(existingAlert?.keyWord || "");
  const [name, setName] = useState<string>(
    existingAlert?.name || "Nom de l'alerte"
  );
  const [showError, setShowError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { createAlert, updateAlert, loading, error } = useAlert();

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  const renderName = (error: string | null) => {
    if (error) {
      return "Le nom de l'alerte existe déjà";
    } else {
      return "Personnnalisez le nom de l'alerte";
    }
  };

  const handleFilterChange = useCallback(
    (id: number, newFilter: Partial<Filter>) => {
      setFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.id === id ? { ...filter, ...newFilter } : filter
        )
      );
    },
    []
  );

  const addFilter = useCallback(() => {
    const id = filters.length;
    setFilters((prevFilters) => [
      ...prevFilters,
      {
        id,
        metric: [],
        symbol: [],
        value: [],
      },
    ]);
    console.log(filters);
  }, [filters]);

  const removeFilter = useCallback((id: number) => {
    setFilters((prevFilters) =>
      prevFilters.filter((filter) => filter.id !== id)
    );
  }, []);
  const renderFilters = useMemo(
    () =>
      filters.map((filter) => (
        <FilterContent key={filter.id}>
          <FilterAlert
            id={filter.id}
            onRemove={() => removeFilter(filter.id)}
            onFilterChange={handleFilterChange}
            //textId={""}
          />
        </FilterContent>
      )),
    [filters, handleFilterChange, removeFilter]
  );

  const handleSaveAlert = async () => {
    if (keyword) {
      try {
        const filterData = filters.map((filter) => ({
          metric: filter.metric[0]?.value,
          symbol: filter.symbol[0]?.value,
          value: filter.value.map((value) => value.value),
        }));

        const alertData = {
          keyWord: keyword,
          name: name,
          isLive: isLive,
          filters: filterData,
        };

        let result;
        if (alertId) {
          result = await updateAlert(alertId, alertData);
        } else {
          result = await createAlert(alertData);
        }
        console.log("Alert saved:", result);
        // Add navigation back to the alerts list or show success message
      } catch (err) {
        console.error("Error saving alert:", err);
        setShowError(true);
      } finally {
        navigate("/alerts");
      }
    }
  };

  const handleInputClick = () => {
    if (name === "Nom de l'alerte") {
      setName("");
      setIsEditing(true);
    }
  };

  return (
    <PageContainer>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <ConfigAlertContainer>
        <Header>
          <BackButton />
          <TitleHeader>
            {alertId ? "Modifier l'alerte" : "Créer une nouvelle alerte"}
          </TitleHeader>
        </Header>
        <Details>
          <Name>
            <InputHeader
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onClick={handleInputClick}
              onBlur={handleInputClick}
              isEditing={isEditing}
            />
            <SubText>{renderName(error)}</SubText>
          </Name>
          <FilterContainer>
            <TitleHeader>Filtres</TitleHeader>

            <Filters>
              <Description>
                <Tooltip>
                  <Interaction>Mot clé</Interaction>
                </Tooltip>
                <Tooltip>
                  <Interaction>=</Interaction>
                </Tooltip>
                <KeywordInput
                  type="text"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </Description>
            </Filters>
            {renderFilters}
            <ChoiceText onClick={addFilter}>+ Ajouter un filtre</ChoiceText>
            <Tooltip>
              <Interaction>Ajouter un mot clé ou un thème</Interaction>
            </Tooltip>
          </FilterContainer>
          <FilterContainer>
            <TitleHeader>Notifications</TitleHeader>
            <ToggleContainer>
              <ToggleLabel>En direct</ToggleLabel>
              <Tooltip>
                <Toggle isOn={isLive} onToggle={() => setIsLive(isLive)} />{" "}
                {/* Chane to !isLive when live */}
              </Tooltip>
            </ToggleContainer>
          </FilterContainer>
          <ButtonContainer>
            <Button
              typeButton="primary"
              onClick={handleSaveAlert}
              disabled={loading || !keyword}
            >
              {loading ? "Sauvegarde en cours..." : "Sauvegarder l'alerte"}
            </Button>
          </ButtonContainer>
        </Details>
        {showError && (
          <ErrorOverlay
            error={error || ""}
            onClose={() => setShowError(false)}
          />
        )}
      </ConfigAlertContainer>
    </PageContainer>
  );
};

export default ConfigAlert;
