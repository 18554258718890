import React from "react";
import styled from "styled-components";

interface InteractionProps {
  children: React.ReactNode;
}

const Interactionhero = styled.div`
  display: flex;
  padding: 1px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--Main-color, #ff734f);
  background: var(--Second-color, #fff7f2);
  cursor: pointer;
`;

const Text = styled.div`
  color: var(--Main-color, #ff734f);
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

const Interaction: React.FC<InteractionProps> = ({ children }) => {
  return (
    <Interactionhero>
      <Text>{children}</Text>
    </Interactionhero>
  );
};

export default Interaction;
