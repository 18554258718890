import React from "react";
import styled from "styled-components";

const AlertContainer = styled.div`
  display: flex;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  background: #e70000;
  width: fit-content;
  min-width: 15px;
`;

const AlertNumber = styled.span`
  color: #fff;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

interface AlertProps {
  number: number;
}

const Alert: React.FC<AlertProps> = ({ number }) => (
  <AlertContainer>
    <AlertNumber>{number}</AlertNumber>
  </AlertContainer>
);

export default Alert;
