import { UserCircle2 } from "lucide-react";
import React, { useState } from "react";
import styled from "styled-components";

interface ProfilPicProps {
  deputyId?: string;
  size?: number;
  profilPage?: boolean;
}

const Container = styled.div<{ size?: number; profilPage?: boolean }>`
  width: ${({ size }) => size || 24}px;
  height: ${({ size }) => size || 24}px;
  background-color: ${({ profilPage }) =>
    profilPage ? "transparent" : "#3c3c3d"};
  border-radius: ${({ profilPage }) => (profilPage ? "0" : "50%")};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Profilimg = styled.img`
  //width: 100%;
  height: 100%;
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 70%;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(35, 33, 41, 1) 10%,
    rgba(35, 33, 41, 0) 100%
  );
  z-index: 1;
`;

const ProfilPic: React.FC<ProfilPicProps> = ({
  deputyId,
  size,
  profilPage,
}) => {
  const [imgError, setImgError] = useState(false);

  return (
    <Container size={size} profilPage={profilPage}>
      {imgError ? (
        <UserCircle2 size={size} strokeWidth={1} color="#eeeeee" />
      ) : (
        <>
          <Profilimg
            onError={() => setImgError(true)}
            src={
              "https://res.cloudinary.com/dhpdocirj/image/upload/" +
              String(deputyId)
            }
          />
          {profilPage && <GradientOverlay />}
        </>
      )}
    </Container>
  );
};

export default ProfilPic;
