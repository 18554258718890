import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Amendment, UseFilesDataResponseDetails } from "../types/Amendments";

const useAmendmentDetails = (
  amendmentId?: string
): UseFilesDataResponseDetails => {
  const [data, setData] = useState<Amendment | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const params: any = {
          amendmentId: amendmentId,
        };

        /*
        const response = await axios.get(`${MAIN_URL}textprovider/amendmentdetails`, {
          params,
        });
        */

        const response = await axios.get(
          `https://api.oparl-app.com/textprovider/amendmentdetails`,
          {
            params,
          }
        );
        setData(response.data);
        console.log("response.data", response.request);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [amendmentId]);

  return { data, loading, error };
};

export default useAmendmentDetails;
