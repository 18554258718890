import styled from "styled-components";
import React, { useState } from "react";
import { BellRing, Trash2 } from "lucide-react";
import useTextSaved from "../../hooks/useTextSaved";

const SpanContainer = styled.span`
  position: relative;
`;

const HighlightedText = styled.span`
  background-color: #ff734f;
  color: #fff;
  font-weight: 700;
  border-radius: 2px;
`;

const Overlay = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f5f5f5;
  }
`;

interface HighlightedSpanProps {
  text: string;
  onCopy?: () => void;
  paragraphId: string;
  userId: number;
  onDelete: (text: string) => void; // Add this line
}

const HighlightedSpan: React.FC<HighlightedSpanProps> = ({
  text,
  paragraphId,
  onCopy,
  userId,
  onDelete, // Add this line
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const { deleteHighlight } = useTextSaved(text, userId);

  const handleDelete = async () => {
    try {
      await deleteHighlight(paragraphId);
      onDelete(text); // Call the onDelete prop after successful deletion
    } catch (error) {
      console.error("Error deleting highlight:", error);
    }
  };

  return (
    <SpanContainer
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => setShowOverlay(false)}
    >
      <HighlightedText>{text}</HighlightedText>
      {showOverlay && (
        <Overlay>
          <IconButton onClick={onCopy} title="Copy">
            <BellRing size={16} />
          </IconButton>
          <IconButton onClick={handleDelete} title="Delete">
            <Trash2 size={16} />
          </IconButton>
        </Overlay>
      )}
    </SpanContainer>
  );
};

export default HighlightedSpan;
